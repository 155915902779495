import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const PrivacyEN = () => {
    return (
        <div className='privacy'>
            <div id='BgImage' className='h-[100vh] bg-black-op-66 bg-top bg-cover pl-5 flex pt-6 justify-center items-center relative' >
                <AnimationOnScroll animateIn="animate__heartBeat" animateOnce>
                    {/* <img src='/img/white-no-background.png' alt='logo' width={'45%'} /> */}
                    <h1 className='text-white text-gr font-bold text-center bg-tr uppercase laptop:text-xl'>
                        {/* SERVICES */}
                        Privacy policy
                    </h1>
                    {/* <h1 className='text-gr  font-bold mb-0 text-center text-red-600 px-3 py-6'>
                    </h1> */}
                </AnimationOnScroll>
                <div id='downdown' className='absolute bottom-8 bg-gray-700 p-2 hover:scale-110 transition-all rounded hover:cursor-pointer' onClick={() => {
                    const hDiv = document.getElementById('downdown')

                    window.scrollTo({ top: (hDiv.clientHeight + 500), behavior: 'smooth' })
                }}>
                    <KeyboardArrowDownIcon fontSize='large' className='text-red-600 hover:text-white transition-all' />
                </div>
            </div>
            <div className='m-10 laptop:mx-3'>
                <p className='text-gray-600  rounded py-4 px-6 mb-4'>
                    GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. sets out in this section the Company’s Privacy Policy on the personal information that the user can provide when visiting our website. GLOBAL CORPORATION BUSINESS LAWYERS S.L.P.’s privacy
                    policy is governed by current legislation on the protection of personal data and the Act on Services of the Information Society and Electronic Commerce.


                </p>
                <p className='font-bold ml-10 text-gray-600 laptop:mr-5' style={{ fontWeight: 'bold' }}>
                    1. Use and processing of personal data
                </p>
                <br />
                <p className='text-gray-600 mx-10'>
                    In accordance with the provisions of Organic Law 15/1999, of December 13, on the Protection of Personal Data (hereinafter LOPD), GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. informs you that the personal data by you,
                    provided, as well as the data accessible after browsing, will be incorporated into the automated files of GLOBAL CORPORATION BUSINESS LAWYERS S.L.P.

                    <br />
                    GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. may collect information from users through this website by any of the following means:
                </p>
                <br />
                <p className='text-gray-600 mx-10'>
                    – Information provided by the user himself via contact addresses (e-mail).
                    <br />
                    – Registration process for certain services provided on the website.
                </p>
                <br />

                {/* <br /> */}
                <p className='text-gray-600 px-6'>
                    Personal identifying information will be used exclusively to respond to your requests and to provide you with information about GLOBAL CORPORATION BUSINESS LAWYERS S.L.P.
                    <br />
                    In turn, it can be used for other purposes, but on condition that it is requested beforehand and that its express and specific consent is obtained.
                    <br />
                    The data you provide will be used exclusively to provide you with information services in the area for which they were requested, allowing the registration data to be updated in the event that you do not wish
                    to receive further information, or modify the content thereof.
                    <br />
                    GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. processes personal information in strict compliance with the legislation on Personal Data, in particular Organic Law 15/1999, Protection of Personal Data, and RD 1720/2007.

                    <br />
                    In this sense, all personal data collected will be incorporated into the files recorded in the General Data Protection Register on behalf of GLOBAL CORPORATION BUSINESS LAWYERS S.L.P., respecting all the established obligations,
                    in particular with regard to compliance with the principles of information and consent.

                    <br />
                    In addition, GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. has in place, in accordance with the Security Measures Regulations, security and operating policies and procedures to protect your personal information against
                    loss, misuse, modification or destruction, involuntary, while maintaining confidentiality.

                    <br />
                    In cases where it is necessary to fill out a form (registration for seminars or events), or send an e-mail
                    (contact: contact@gcblawyers.com) and click on the send button, its realization will necessarily involve that he has been informed (under Article 5 of LOPD) and, where applicable, has given his consent (under Article 6
                    of LOPD) to the content of this privacy policy.

                    <br />
                    Thus, each time GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. asks you for personal data, it will include the corresponding legal clause and a link (link) linked to this Policy in order to share with you your rights and obligations established in the Organic Law on Data Protection (LOPD) and Law of Services of the Information Society and Electronic Commerce (LSSICE).
                </p>
                <br />
                <br />
                <p className='font-bold ml-10 text-gray-600 laptop:mr-5' style={{ fontWeight: 'bold' }}>
                    2. Exercise of rights: access, rectification, cancellation and opposition
                </p>
                <br />
                <p className='text-gray-600 mx-10'>
                    The natural persons who have provided their data to GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. may contact it, in its capacity as person in charge of the file, in order to be able to exercise free of charge their rights of access, rectification, cancellation and opposition concerning the data included in your files. Given the confidentiality of the information, you, will not be able to exercise its rights by telephone, because this
                    means does not allow proving its identity as holder of the recorded data. The interested party may exercise their rights by written communication addressed to GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. at the following address: ATTN: LOPD Manager. Paseo de la Castellana nº 81, C.P. 28046 (Madrid), with the
                    following reference in his letter: “Exercise of rights” (with his written and signed request, he must certify his personality by bringing a photocopy of his National Identity Document (D.N.I.).

                </p>
                <br />
                <br />
                <p className='font-bold ml-10 text-gray-600 laptop:mr-5' style={{ fontWeight: 'bold' }}>
                    3. Modification of the privacy policy

                </p>
                <br />
                <p className='text-gray-600 mx-10'>
                    GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. reserves the right to modify its Confidentiality Policy, according to its own criteria, or motivated by a doctrinal change of the Spanish Protection Agency of Data, legislative or
                    jurisprudential. Any modification of the Privacy Policy will be published at least ten days before its effective application. Use of the web after these changes will imply your express acceptance of them.                    <br />
                </p>
            </div>
            <br />
            <br />
        </div>
    )
}

export default PrivacyEN