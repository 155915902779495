import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
const AvisES = () => {
    return (
        <div>  <div id='BgImage' className='h-[100vh] bg-black-op-66 bg-top bg-cover pl-5 flex pt-6 justify-center items-center relative' >
            <AnimationOnScroll animateIn="animate__heartBeat" animateOnce>
                {/* <img src='/img/white-no-background.png' alt='logo' width={'45%'} /> */}
                <h1 className='text-white text-gr font-bold text-center bg-tr uppercase laptop:text-3xl'>
                    {/* SERVICES */}
                    AVISO LEGAL
                </h1>
                {/* <h1 className='text-gr  font-bold mb-0 text-center text-red-600 px-3 py-6'>
        </h1> */}
            </AnimationOnScroll>
            <div id='downdown' className='absolute bottom-8 bg-gray-700 p-2 hover:scale-110 transition-all rounded hover:cursor-pointer' onClick={() => {
                const hDiv = document.getElementById('downdown')
                window.scrollTo({ top: (hDiv.clientHeight + 500), behavior: 'smooth' })
            }}>
                <KeyboardArrowDownIcon fontSize='large' className='text-red-600 hover:text-white transition-all' />
            </div>
        </div>
            <div className='m-10 laptop:mx-5'>
                <p className='text-gray-600 border rounded py-4 px-6 mb-4 '>
                    GLOBAL CORPORATION BUSINESS LAWYERS Société Sociedad Limitada Profesional, cuyos datos figuran continuación,
                    <br />
                    <br />
                    Sede social : GLOBAL CORPORATION BUSINESS LAWYERS S.L.P
                    <br />
                    NIF B-09927203
                    <br />
                    <br />
                    Inscrite au registre du commerce de Madrid, au Tome 38.989 Folio 1 Section 8 Feuille M-692817 Inscription
                    <br />
                    Siège social : Calle Vistalegre 46, 28270 Colmenarejo-Madrid
                    <br />
                    Oficinas principales : Calle Vistalegre 46, 28270 Colmenarejo-Madrid
                    <br />
                    Para comunicarse con nosotros, envíe un e-mail a
                    <a href='mailto:contact@gcblawyers.com' className='ml-2 font-bold'>  contact@gcblawyers.com</a>

                    <br />
                    <br />
                    en cumplimiento de la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio electrónico, le informa de que el hecho de acceder a este website implica el conocimiento y aceptación de los siguientes términos y condiciones:
                    <ul className='ul-number text-gray-600  py-4 px-6'>
                        <li>
                            <b>Usuarios : </b>
                            Se entenderá por Usuario a la persona que acceda, navegue, utilice o participe en los servicios y actividades, gratuitas u onerosas, desarrolladas en gcblawyers.com                            Par utilisateur, on entend la personne qui accède, navigue,
                        </li>
                        <li>

                            <b>Uso del sitio Web, sus servicios y contenidos : </b>
                            Se entenderá que el acceso o la mera utilización de gcblawyers.com por parte del Usuario implica la adhesión de éste a las condiciones generales que gcblawyers.com tenga publicadas en cada momento en el que acceda a gcblawyers.com.
                            <br />
                            {/* En ce qui concerne les contenus (informations, textes, graphiques, fichiers sonores et/ou images, photographies, dessins, etc.), il est interdit : – Leur reproduction, distribution ou modification, à moins que l’autorisation de leurs titulaires légitimes ou soit légalement autorisée. – toute violation des droits de GLOBAL CORPORATION BUSINESS LAWYERS ou de ses titulaires légitimes. – leur utilisation à des fins commerciales ou publicitaires autres que celles strictement autorisées. – toute tentative d’obtenir le contenu du site Web par tout autre moyen que ceux mis à la disposition des utilisateurs ainsi que ceux habituellement utilisés sur le réseau, à condition qu’ils ne causent aucun préjudice au site Web de GLOBAL CORPORATION BUSINESS LAWYERS. */}
                            Respecto de los contenidos (informaciones, textos, gráficos, archivos de sonido y/o imagen, fotografías, diseños, etc.), se prohíbe: – Su reproducción, distribución o modificación, a menos que se cuente con la autorización de sus legítimos titulares o resulte legalmente permitido. – Cualquier vulneración de los derechos de GLOBAL CORPORATION BUSINESS LAWYERS o de sus legítimos titulares sobre los mismos. – Su utilización para todo tipo de fines comerciales o publicitarios, distintos de los estrictamente permitidos. – Cualquier intento de obtener los contenidos del sitio Web por cualquier medio distinto de los que se pongan a disposición de los usuarios así como de los que habitualmente se empleen en la red, siempre que no causen perjuicio alguno a la web de GLOBAL CORPORATION BUSINESS LAWYERS.

                        </li>
                        <li>
                            <b>Modificación unilateral : </b>
                            GLOBAL CORPORATION BUSINESS LAWYERS se reserva la facultad de efectuar, en cualquier momento y sin necesidad de previo aviso, modificaciones y actualizaciones la información contenida en su web o en la configuración y presentación de éste.
                        </li>
                        <li>
                            <b>Hiperenlaces : </b>
                            En gcblawyers.com, el Usuario podrá encontrar enlaces a otras páginas web mediante diferentes botones, links, banners, etc., los cuales son gestionados por terceros. GLOBAL CORPORATION BUSINESS LAWYERS no tiene facultad ni medios humanos ni técnicos para conocer, controlar ni aprobar toda la información, contenidos, productos o servicios facilitados por otros sitios web a los que se establecen enlaces desde gcblawyers.com. En consecuencia,
                            no puede asumir ningún tipo de responsabilidad por cualquier aspecto relativo a la página web a la que se establece un enlace desde gcblawyers.com, en concreto, a título enunciativo y no taxativo, sobre su funcionamiento, acceso, datos, información, archivos, calidad y fiabilidad de sus productos y servicios, sus propios enlaces y/o cualquiera de sus contenidos, en general.
                        </li>
                        <li>
                            <b>Exclusión de garantías y responsabilidad : </b>
                            GLOBAL CORPORATION BUSINESS LAWYERS  no garantiza la inexistencia de errores en el acceso al web, en su contenido, ni que éste se encuentre actualizado, aunque desarrollará sus mejores esfuerzos para, en su caso, evitarlos, subsanarlos o actualizarlos. Tanto el acceso a este web como el uso que pueda hacerse de la información contenida en el mismo es de la exclusiva responsabilidad de quien lo realiza.
                        </li>
                    </ul>
                    <p>
                        {/* <br /> */}
                        En caso de que existiese usuario y clave para acceder a determinados contenidos /servicios, el usuario será responsable de todas las acciones que realice con su identificador de usuario. En concreto, será responsable de escoger, como clave y como recordatorio de la misma, contraseñas y frases robustas, esto es, cifras y letras e incluso, en los sistemas que lo permitan, signos de puntuación y caracteres especiales, difíciles de adivinar. En especial, el usuario evitará escoger palabras del diccionario, palabras que estén relacionadas con él mismo (nombre de familiar, domicilio, fecha de nacimiento, etc.) o sencillas de adivinar (combinaciones de nombres con meses, prefijos y sufijos, etc.). GLOBAL CORPORATION BUSINESS LAWYERS no responderá de ninguna consecuencia, daño o perjuicio que pudieran derivarse de dicho acceso o uso de la información.
                        <br />
                        <br />
                        GLOBAL CORPORATION BUSINESS LAWYERS no asume responsabilidad alguna derivada de la conexión o contenidos de los enlaces de terceros a los que se hace referencia en la web.
                        <br />
                        <br />
                        La utilización no autorizada de la información contenida en esta web, así como la lesión de los derechos de Propiedad Intelectual o Industrial de GLOBAL CORPORATION BUSINESS LAWYERS, dará lugar a las responsabilidades legalmente establecidas. GLOBAL CORPORATION BUSINESS LAWYERS no se hace responsable de los posibles errores de seguridad que se puedan producir ni de los posibles daños que puedan causarse al sistema informático del usuario (hardware y software), los ficheros o documentos almacenados en el mismo, como consecuencia de la presencia de virus en el ordenador del usuario utilizado para la conexión a los servicios y contenidos de gcblawyers.com, de un mal funcionamiento del navegador o del uso de versiones no actualizadas del mismo.
                        <br />
                        <br />
                    </p>
                    {/* Les cookies sont un outil utilisé par les serveurs Web pour stocker et récupérer des informations sur leurs visiteurs. Ce sont de petits fichiers texte que nous envoyons à votre ordinateur pour garder une trace de vos préférences et les rappeler à votre retour. GLOBAL CORPORATION BUSINESS LAWYERS utilise des cookies pour faciliter la navigation sur son site et pour améliorer l’efficacité et la personnalisation des services offerts aux utilisateurs. Les cookies utilisés par GLOBAL CORPORATION BUSINESS LAWYERS permettent de stocker des informations sur les préférences et la langue de l’utilisateur, afin de pouvoir afficher des contenus et des offres d’intérêt particulier en accord avec votre profil et d’enregistrer le nombre de fois qu’un message particulier est affiché à un utilisateur (généralement des nouveautés sur le portail). */}
                    {/* L’utilisateur peut librement décider de l’implantation ou non sur son disque dur des cookies utilisés par GLOBAL CORPORATION BUSINESS LAWYERS. */}
                    {/* <br /> */}
                    {/* <br /> */}
                    {/* Dans ce sens, l’utilisateur peut configurer son navigateur pour accepter ou refuser par défaut tous les cookies ou pour recevoir un avertissement à l’écran de réception de chaque cookie et décider à ce moment-là de son implantation ou non sur son disque dur. Pour ce faire, nous vous suggérons la section d’aide de votre navigateur pour savoir comment modifier les paramètres que vous utilisez actuellement. */}
                    <ul className='ul-number text-gray-600  py-4 px-6'>
                        <li>
                            <b>Duración : </b>
                            La vigencia temporal de estas condiciones generales coincide, por lo tanto, con el tiempo de su exposición, hasta que sean modificadas total o parcialmente, momento en el cual pasarán a tener vigencia las condiciones generales modificadas.
                            <br />
                            Con independencia de lo dispuesto en las condiciones particulares, GLOBAL CORPORATION BUSINESS LAWYERS podrá dar por terminado, suspender o interrumpir, en cualquier momento sin necesidad de preaviso, el acceso a los contenidos de la página, sin posibilidad por parte del Usuario de exigir indemnización alguna. Tras dicha extinción, seguirán vigentes las prohibiciones de uso de los contenidos expuestas anteriormente en las presentes condiciones.
                        </li>
                        <li>
                            <b>Legislación aplicable y jurisdicción : </b>
                            Las presentes Condiciones Generales se regirán por la legislación española. En especial, será de aplicación la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio electrónico y la Ley Orgánica 15/1999, de Protección de Datos de Carácter Personal (LOPD) y su normativa de desarrollo. La jurisdicción española el único fuero para dirimir conflictos. GLOBAL CORPORATION BUSINESS LAWYERS y el usuario, con renuncia expresa a cualquier otro fuero que pudiera corresponderles, se someten a la Jurisdicción de los Juzgados y Tribunales del domicilio del usuario para cuantas cuestiones pudieran suscitarse o acciones ejercitarse derivadas de la prestación del servicio del Web y de sus servicios y contenidos y sobre la interpretación, aplicación, cumplimiento o incumplimiento de lo aquí establecido. En el supuesto de que el Usuario tenga su domicilio fuera de España, GLOBAL CORPORATION BUSINESS LAWYERS y el usuario, con renuncia expresa a cualquier otro fuero que pudiera corresponderles, se someten a la Jurisdicción de los Juzgados y Tribunales de Madrid.
                            <br />
                        </li>
                    </ul>
                </p>
            </div></div >
    )
}

export default AvisES