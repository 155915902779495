import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
const Avis = () => {
    return (
        <div>  <div id='BgImage' className='h-[100vh] bg-black-op-66 bg-top bg-cover pl-5 flex pt-6 justify-center items-center relative' >
            <AnimationOnScroll animateIn="animate__heartBeat" animateOnce>
                {/* <img src='/img/white-no-background.png' alt='logo' width={'45%'} /> */}
                <h1 className='text-white text-gr font-bold text-center bg-tr uppercase laptop:text-3xl'>
                    {/* SERVICES */}
                    Avis légal
                </h1>
                {/* <h1 className='text-gr  font-bold mb-0 text-center text-red-600 px-3 py-6'>
        </h1> */}
            </AnimationOnScroll>
            <div id='downdown' className='absolute bottom-8 bg-gray-700 p-2 hover:scale-110 transition-all rounded hover:cursor-pointer' onClick={() => {
                const hDiv = document.getElementById('downdown')
                window.scrollTo({ top: (hDiv.clientHeight + 500), behavior: 'smooth' })
            }}>
                <KeyboardArrowDownIcon fontSize='large' className='text-red-600 hover:text-white transition-all' />
            </div>
        </div>
            <div className='m-10 laptop:mx-5'>
                <p className='text-gray-600 border rounded py-4 px-6 mb-4 '>
                    GLOBAL CORPORATION BUSINESS LAWYERS Société Professionnelle à Responsabilité Limitée, dont les données figurent ci-après,
                    <br />
                    <br />
                    Raison sociale : GLOBAL CORPORATION BUSINESS LAWYERS S.L.P
                    <br />
                    NIF B-09927203
                    <br />
                    <br />
                    Inscrite au registre du commerce de Madrid, au Tome 38.989 Folio 1 Section 8 Feuille M-692817 Inscription
                    <br />
                    Siège social : Calle Vistalegre 46, 28270 Colmenarejo-Madrid
                    <br />
                    Bureau principaux : Calle Vistalegre 46, 28270 Colmenarejo-Madrid
                    <br />
                    Pour communiquer avec nous, envoyez un e-mail à 
                    <a href='mailto:contact@gcblawyers.com' className='ml-2 font-bold'>  contact@gcblawyers.com</a>

                    <br />
                    <br />
                    En conformité avec la Loi 34/2002, du 11 juillet, des services de la société de l’information et du commerce électronique, vous informe que le fait d’accéder à ce site implique la connaissance et l’acceptation des termes et conditions suivants:
                    <ul className='ul-number text-gray-600  py-4 px-6'>
                        <li>
                            <b>Utilisateurs : </b>
                            Par utilisateur, on entend la personne qui accède, navigue, utilise ou participe aux services et activités, gratuits ou onéreux, développés dans gcblawyers.com
                        </li>
                        <li>
                            <b>Utilisation du site Web, de ses services et de son contenu : </b>
                            L’accès ou la simple utilisation de gcblawyers.com  par l’Utilisateur implique l’adhésion de l’Utilisateur aux conditions générales publiées par gcblawyers.com  à chaque fois qu’il accède à gcblawyers.com .
                            <br />
                            En ce qui concerne les contenus (informations, textes, graphiques, fichiers sonores et/ou images, photographies, dessins, etc.), il est interdit : – Leur reproduction, distribution ou modification, à moins que l’autorisation de leurs titulaires légitimes ou soit légalement autorisée. – toute violation des droits de GLOBAL CORPORATION BUSINESS LAWYERS ou de ses titulaires légitimes. – leur utilisation à des fins commerciales ou publicitaires autres que celles strictement autorisées. – toute tentative d’obtenir le contenu du site Web par tout autre moyen que ceux mis à la disposition des utilisateurs ainsi que ceux habituellement utilisés sur le réseau, à condition qu’ils ne causent aucun préjudice au site Web de GLOBAL CORPORATION BUSINESS LAWYERS.
                        </li>
                        <li>
                            <b>Modification unilatérale : </b>
                            GLOBAL CORPORATION BUSINESS LAWYERS se réserve la faculté d’effectuer, à tout moment et sans préavis, des modifications et des mises à jour des informations contenues dans son site ou dans la configuration et la présentation de celui-ci.
                        </li>
                        <li>
                            <b>Hyperliens : </b>
                            Dans  gcblawyers.com , l’Utilisateur peut trouver des liens vers d’autres pages Web à l’aide de différents boutons, liens, bannières, etc., qui sont gérés par des tiers. GLOBAL CORPORATION BUSINESS LAWYERS n’a pas le pouvoir ni les moyens humains ou techniques de connaître, de contrôler ou d’approuver toutes les informations, contenus, produits ou services fournis par d’autres sites Web auxquels des liens sont établis à partir de  gcblawyers.com . En conséquence, GLOBAL CORPORATION BUSINESS LAWYERS ne peut assumer aucune responsabilité pour tout aspect relatif au site web auquel un lien est établi à partir de  gcblawyers.com , notamment, à titre indicatif et non limitatif, sur son fonctionnement, accès, données, informations, fichiers, qualité et fiabilité de vos produits et services, vos propres liens et/ou l’un de vos contenus, en général.
                        </li>
                        <li>
                            <b>Exclusion des garanties et de la responsabilité : </b>
                            GLOBAL CORPORATION BUSINESS LAWYERS ne garantit pas l’absence d’erreurs dans l’accès au Web, dans son contenu, ni que celui-ci est mis à jour, même si elle fera de son mieux pour, le cas échéant, les éviter, les corriger ou les mettre à jour. L’accès à ce site Web et l’utilisation qui peut être faite des informations qu’il contient relèvent de la seule responsabilité de celui qui le fait.
                            <br />
                            Dans le cas où il existe un utilisateur et un mot de passe pour accéder à certains contenus /services, l’utilisateur est responsable de toutes les actions qu’il effectue avec son identifiant d’utilisateur. En particulier, il sera responsable de choisir, comme clé et comme rappel, des mots de passe et des phrases robustes, c’est-à-dire des chiffres et des lettres et même, dans les systèmes qui le permettent, des signes de ponctuation et des caractères spéciaux, difficiles à deviner. En particulier, l’utilisateur évitera de choisir des mots dans le dictionnaire, des mots qui lui sont liés (nom de famille, adresse, date de naissance, etc.) ou des mots simples à deviner (combinaisons de noms avec mois, préfixes et suffixes, etc.). GLOBAL CORPORATION BUSINESS LAWYERS ne sera pas responsable des conséquences, dommages ou préjudices qui pourraient résulter de cet accès ou utilisation des informations.
                            <br />
                            GLOBAL CORPORATION BUSINESS LAWYERS n’assume aucune responsabilité découlant de la connexion ou du contenu des liens tiers auxquels il est fait référence sur le site.
                            <br />
                            <br />
                            L’utilisation non autorisée de l’information contenue dans ce site, ainsi que la lésion des droits de propriété intellectuelle ou industrielle de GLOBAL CORPORATION BUSINESS LAWYERS, entraînera les responsabilités légalement établies. GLOBAL CORPORATION BUSINESS LAWYERS décline toute responsabilité quant aux éventuelles erreurs de sécurité qui pourraient se produire et aux dommages qui pourraient être causés au système informatique de l’utilisateur (matériel et logiciel), aux fichiers ou aux documents qui y sont stockés, en raison de la présence de virus dans l’ordinateur de l’utilisateur utilisé pour la connexion aux services et au contenu de gcblawyers.com , d’un dysfonctionnement du navigateur ou de l’utilisation de versions non mises à jour du navigateur.
                            <br />
                            <br />
                            {/* Les cookies sont un outil utilisé par les serveurs Web pour stocker et récupérer des informations sur leurs visiteurs. Ce sont de petits fichiers texte que nous envoyons à votre ordinateur pour garder une trace de vos préférences et les rappeler à votre retour. GLOBAL CORPORATION BUSINESS LAWYERS utilise des cookies pour faciliter la navigation sur son site et pour améliorer l’efficacité et la personnalisation des services offerts aux utilisateurs. Les cookies utilisés par GLOBAL CORPORATION BUSINESS LAWYERS permettent de stocker des informations sur les préférences et la langue de l’utilisateur, afin de pouvoir afficher des contenus et des offres d’intérêt particulier en accord avec votre profil et d’enregistrer le nombre de fois qu’un message particulier est affiché à un utilisateur (généralement des nouveautés sur le portail). */}
                            {/* L’utilisateur peut librement décider de l’implantation ou non sur son disque dur des cookies utilisés par GLOBAL CORPORATION BUSINESS LAWYERS. */}
                            {/* <br /> */}
                            {/* <br /> */}
                            {/* Dans ce sens, l’utilisateur peut configurer son navigateur pour accepter ou refuser par défaut tous les cookies ou pour recevoir un avertissement à l’écran de réception de chaque cookie et décider à ce moment-là de son implantation ou non sur son disque dur. Pour ce faire, nous vous suggérons la section d’aide de votre navigateur pour savoir comment modifier les paramètres que vous utilisez actuellement. */}
                        </li>
                        <li>
                            <b>Durée : </b>
                            La durée de validité de ces conditions générales coïncide donc avec la durée de leur exposition, jusqu’à ce qu’elles soient modifiées en tout ou en partie, date à laquelle les conditions générales modifiées prendront effet.
                            <br />
                            Indépendamment des dispositions des conditions particulières, GLOBAL CORPORATION BUSINESS LAWYERS peut mettre fin, suspendre ou interrompre, à tout moment sans préavis, l’accès aux contenus du site, sans possibilité pour l’Utilisateur d’exiger une quelconque indemnisation. Après cette extinction, les interdictions d’utilisation des contenus énoncées ci-dessus dans les présentes conditions resteront en vigueur.
                        </li>
                        <li>
                            <b>Droit applicable et compétence : </b>
                            La durée de validité de ces conditions générales coïncide donc avec la durée de leur exposition, jusqu’à ce qu’elles soient modifiées en tout ou en partie, date à laquelle les conditions générales modifiées prendront effet.
                            <br />
                            Les présentes Conditions Générales seront régies par la législation espagnole. En particulier, la loi 34/2002, du 11 juillet, sur les services de la société de l’information et du commerce électronique et la loi organique 15/1999, sur la protection des données à caractère personnel (LOPD) et sa réglementation d’application seront applicables. La juridiction espagnole est la seule juridiction pour régler les conflits. GLOBAL CORPORATION BUSINESS LAWYERS et l’utilisateur, avec renonciation expresse à toute autre juridiction qui pourrait leur correspondre, se soumettent à la Juridiction des Cours et Tribunaux du domicile de l’utilisateur pour toutes les questions qui pourraient être soulevées ou exercées découlant de la prestation du service du Web et de ses services et contenus et sur l’interprétation, mise en œuvre, respect ou non-respect des dispositions de la présente directive. Dans le cas où l’Utilisateur a son domicile en dehors de l’Espagne, GLOBAL CORPORATION BUSINESS LAWYERS et l’utilisateur, avec renonciation expresse à tout autre for qui pourrait leur correspondre, se soumettent à la Juridiction des Tribunaux de Madrid.
                        </li>
                    </ul>
                </p>
            </div></div>
    )
}

export default Avis