import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const SecurityES = () => {
  return (
    <div className=''>
      <div id='BgImage' className='h-[100vh] bg-black-op-66 bg-top bg-cover pl-5 flex pt-6 justify-center items-center relative' >
        <AnimationOnScroll animateIn="animate__heartBeat" animateOnce>
          {/* <img src='/img/white-no-background.png' alt='logo' width={'45%'} /> */}
          <h1 className='text-white text-gr font-bold text-center bg-tr uppercase laptop:text-xl'>
            POLÍTICA DE SEGURIDAD
          </h1>
          {/* <h1 className='text-gr  font-bold mb-0 text-center text-red-600 px-3 py-6'>
                        Politique de privacité
            </h1> */}
        </AnimationOnScroll>
        <div id='downdown' className='absolute bottom-8 bg-gray-700 p-2 hover:scale-110 transition-all rounded hover:cursor-pointer' onClick={() => {
          const hDiv = document.getElementById('downdown')

          window.scrollTo({ top: (hDiv.clientHeight + 500), behavior: 'smooth' })
        }}>
          <KeyboardArrowDownIcon fontSize='large' className='text-red-600 hover:text-white transition-all' />
        </div>
      </div>
      <div className='m-10 py-10 laptop:mx-5'>
        <p className='text-gray-600 border rounded p-4 mb-4'>
          Es política de GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. tener un sistema de gestión de la seguridad de la información adecuado al entorno actual de las tecnologías, por entender que la información es un activo fundamental para la prestación de nuestros servicios y la toma de decisiones eficientes, razón por la cual existe un compromiso expreso de protección de la información en general como parte de una estrategia orientada a la continuidad del negocio la administración de riesgos y la consolidación de una cultura de seguridad.
          <br />
          Para ello, GLOBAL CORPORATION BUSINESS LAWYERS S.L.P.  adquiere los siguientes compromisos:


          <ul className='text-gray-600  p-8 ul-non laptop:px-1'>
            <li>
              Definir, implantar y mantener al día un Sistema de Gestión de la Seguridad de la Información basado en el estándar ISO 27001, cuyos elementos básicos se desarrollan en los procedimientos de aplicación. Todo ello con el fin de mejorar continuamente la eficacia y eficiencia del desempeño de la organización, mediante la consideración de las partes interesadas y el cumplimiento de los objetivos de la Seguridad de la Información
            </li>
            <li>  Dotar a la Empresa de los recursos humanos y técnicos necesarios, para asegurar la información</li>
            <li>Crear un Comité de Seguridad de la Información, que será el responsable del mantenimiento, revisión y mejora del Sistema de Gestión de Seguridad de la Información de la empresa</li>
            <li>Asegurar la revisión continua del Sistema de Gestión de la Seguridad de la Información, a través de la realización de auditorías internas de calidad, que permitan una revisión crítica y faciliten la mejora del mismo, y la revisión periódica por la Dirección
            </li>
            <li>
              Crear un Plan de Contingencia del negocio que asegure la continuidad de las operaciones ante la ocurrencia de eventos no previstos que puedan afectar a la seguridad de la información              </li>
            <li>Asegurar que se toman las medidas pertinentes para asegurar la seguridad de la información
            </li>
            <li>Informar y formar al personal de la necesidad de:
              <ul className='mx-8 ul-non-inside laptop:mx-2'>
                <li>
                  El deber de confidencialidad y de secreto que se debe tener respecto de la información que tenga acceso atendiendo a sus funciones dentro de la organización
                </li>
                <li>
                  Acceder a la información a través de las contraseñas que tienen el carácter de confidencial e intransferible
                </li>
                <li>
                  Signaler tout incident dans le système de sécurité de l’information
                  Reportar cualquier incidencia en el sistema de seguridad de la información
                </li>
                <li>
                  Usar los recursos proporcionados por la empresa con fines exclusivamente relacionados con la actividad que desarrollan en la organización
                </li>
              </ul>
            </li>
            <li>
              Cumplir en todo momento con la Legislación vigente aplicable a nuestra actividad. Con el cumplimiento de dichos compromisos, GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. garantiza la consecución de los siguientes objetivos generales:

              <ul className='mx-8 ul-non-inside laptop:px-2'>
                <li>
                  Evitar, transferir, asumir o reducir los riesgos debido al establecimiento y seguimiento de controles sobre ellos, hasta alcanzar un nivel asumible por nuestra organización. De este modo, si se produce una incidencia, los danos se minimizan y la continuidad del negocio está asegurada
                </li>
                <li>Conseguir un ahorro de costes derivado de una racionalización de los recursos. Se eliminan las inversiones innecesarias e ineficientes como las producidas por desestimar o sobrestimar riesgos
                </li>
                <li>La seguridad se considera un sistema y se convierte en una actividad de gestión
                </li>
                <li>Asegurar el cumplimiento de la legislación vigente y evitar riesgos y costes innecesarios
                </li>
                <li>Tener un control de:
                  <ul className='mx-8 laptop:mx-2'>

                    <li>
                      los accesos externos a los sistemas que albergan aplicaciones a disposición de clientes (públicamente disponibles) para evitar accesos a datos no autorizados o cruzados

                    </li>
                    <li>
                      las incidencias que puedan generarse en el sistema

                    </li>
                    <li>
                      disponibilidad de los servicios y sistemas públicamente disponibles
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              El Sistema de Gestión de Seguridad de la Información contribuirá́ a mejorar la competitividad de la organización diferenciándola del resto de empresas del sector, mejorando la imagen y confianza de nuestra empresa entre clientes, clientes potenciales y proveedores aumentando con todo ello el prestigio de la empresa a nivel nacional e internacional
            </li>
          </ul>
        </p>
        <p className='text-gray-600 border rounded p-4 my-4'>
          Para el cumplimiento de estos objetivos la Dirección establece las medidas necesarias para asegurar que su Sistema de Seguridad de la Información:

          <ul className='ml-10'>
            <li className='pt-4'>
              Es difundido a todas las áreas de la Empresa
            </li>
            <li>
              Es entendido
            </li>
            <li>
              Es aplicado
            </li>
            <li>
              Es revisado y puesto al día
            </li>
          </ul>
        </p>
        <p className='text-gray-600 border rounded p-4 my-4'>
          La Dirección General declara de obligado cumplimiento las exigencias contenidas en la documentación que constituye el Sistema de Gestión de la Seguridad de la Información
        </p>
        {/* <br /> */}
      </div>
    </div>
  )
}
