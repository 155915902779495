import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const Security = () => {
  return (
    <div className=''>
      <div id='BgImage' className='h-[100vh] bg-black-op-66 bg-top bg-cover pl-5 flex pt-6 justify-center items-center relative' >
        <AnimationOnScroll animateIn="animate__heartBeat" animateOnce>
          {/* <img src='/img/white-no-background.png' alt='logo' width={'45%'} /> */}
          <h1 className='text-white text-gr font-bold text-center bg-tr uppercase laptop:text-xl'>
            POLITIQUE DE SÉCURITÉ
          </h1>
          {/* <h1 className='text-gr  font-bold mb-0 text-center text-red-600 px-3 py-6'>
                        Politique de privacité
            </h1> */}
        </AnimationOnScroll>
        <div id='downdown' className='absolute bottom-8 bg-gray-700 p-2 hover:scale-110 transition-all rounded hover:cursor-pointer' onClick={() => {
          const hDiv = document.getElementById('downdown')

          window.scrollTo({ top: (hDiv.clientHeight + 500), behavior: 'smooth' })
        }}>
          <KeyboardArrowDownIcon fontSize='large' className='text-red-600 hover:text-white transition-all' />
        </div>
      </div>
      <div className='m-10 py-10 laptop:mx-5'>
        <p className='text-gray-600 border rounded p-4 mb-4'>
          La politique de  GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. est d’avoir un système de gestion de la sécurité de l’information adapté à l’environnement actuel des technologies, considérant que l’information est un atout essentiel pour la fourniture efficace de nos services et la prise de décision, raison pour laquelle il existe un engagement explicite en faveur de la protection de l’information en général dans le cadre d’une stratégie axée sur la continuité de l’activité, la gestion des risques et la consolidation d’une culture de sécurité.
          <br />
          À cette fin,  GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. prend les engagements suivants :
          <ul className='text-gray-600  p-8 ul-non laptop:px-1'>
            <li>Définir, mettre en place et tenir à jour un système de gestion de la sécurité de l’information fondé sur la norme ISO 27001, dont les éléments de base sont développés dans les procédures d’application. Tout cela afin d’améliorer en permanence l’efficacité et l’efficience de l’organisation, grâce à la prise en compte des parties prenantes et à la réalisation des objectifs de la sécurité de l’information</li>
            <li>Doter l’Entreprise des ressources humaines et techniques nécessaires pour assurer l’information</li>
            <li>Créer un comité de sécurité de l’information, qui sera chargé de la maintenance, de la révision et de l’amélioration du système de gestion de la sécurité de l’information de l’entreprise</li>
            <li>Veiller à ce que le système de gestion de la sécurité de l’information fasse l’objet d’une révision continue, grâce à des audits internes de qualité, qui permettent un examen critique et facilitent l’amélioration du système, et à ce que la direction procède à un examen régulier
            </li>
            <li>Créer un plan d’urgence pour assurer la continuité des opérations en cas d’événements imprévus pouvant affecter la sécurité de l’information
            </li>
            <li>Veiller à ce que des mesures appropriées soient prises pour assurer la sécurité de l’information
            </li>
            <li >Informer et former le personnel sur la nécessité :
              <ul className='mx-8 ul-non-inside laptop:mx-2'>
                <li>Obligation de confidentialité et de secret à respecter en ce qui concerne les informations auxquelles il a accès du fait de ses fonctions au sein de l’organisation
                </li>
                <li>Accès à l’information par mot de passe confidentiel et non transférable
                </li>
                <li>Signaler tout incident dans le système de sécurité de l’information
                </li>
                <li>Utilisation des ressources fournies par l’entreprise à des fins exclusivement liées à l’activité de l’organisation
                </li>
              </ul>
            </li>
            <li>Respecter à tout moment la législation en vigueur applicable à notre activité. En respectant ces engagements,  GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. garantit la réalisation des objectifs généraux suivants :
              <ul className='mx-8 ul-non-inside laptop:px-2'>
                <li>Éviter, transférer, assumer ou réduire les risques par la mise en place et le suivi de contrôles sur ceux-ci, jusqu’à atteindre un niveau supportable par notre organisation. Ainsi, si une incidence se produit, les dommages sont minimisés et la continuité de l’entreprise est assurée

                </li>
                <li>Réaliser des économies de coûts en rationalisant les ressources. Élimination des investissements inutiles et inefficaces, tels que ceux qui ont pour effet de négliger ou de surestimer les risques

                </li>
                <li>La sécurité est considérée comme un système et devient une activité de gestion

                </li>
                <li>Veiller au respect de la législation en vigueur et éviter les risques et les coûts inutiles
                </li>
                <li>Avoir le contrôle de :
                  <ul className='mx-8 laptop:mx-2'>

                    <li>
                      les accès externes aux systèmes qui hébergent des applications à la disposition des clients (publiquement disponibles) pour éviter l’accès à des données non autorisées ou croisées

                    </li>
                    <li>les incidences éventuelles sur le système

                    </li>
                    <li>disponibilité des services et systèmes publics
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>Le système de gestion de la sécurité de l’information contribuera à améliorer la compétitivité de l’organisation en la différenciant des autres entreprises du secteur, en améliorant l’image et la confiance de notre entreprise parmi les clients, clients et fournisseurs potentiels, renforçant ainsi le prestige de l’entreprise aux niveaux national et international
            </li>
          </ul>
        </p>
        <p className='text-gray-600 border rounded p-4 my-4'>
          Pour atteindre ces objectifs, la Direction prend les mesures nécessaires pour que son système de sécurité de l’information :
          <ul className='ml-10'>
            <li className='pt-4'>
              est diffusée dans tous les secteurs de l’Entreprise
            </li>
            <li>
              est comprise
            </li>
            <li>
              est appliquée
            </li>
            <li>
              est revue et mise à jour
            </li>
          </ul>
        </p>
        <p className='text-gray-600 border rounded p-4 my-4'>
          La Direction générale déclare que les exigences contenues dans la documentation constituant le système de gestion de la sécurité de l’information sont respectées.
        </p>
        {/* <br /> */}
      </div>
    </div>
  )
}
