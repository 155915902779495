import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const Privacy = () => {
    return (
        <div className='privacy'>
            <div id='BgImage' className='h-[100vh] bg-black-op-66 bg-top bg-cover pl-5 flex pt-6 justify-center items-center relative' >
                <AnimationOnScroll animateIn="animate__heartBeat" animateOnce>
                    {/* <img src='/img/white-no-background.png' alt='logo' width={'45%'} /> */}
                    <h1 className='text-white text-gr font-bold text-center bg-tr uppercase laptop:text-xl'>
                        {/* SERVICES */}
                        Politique de privacité
                    </h1>
                    {/* <h1 className='text-gr  font-bold mb-0 text-center text-red-600 px-3 py-6'>
                    </h1> */}
                </AnimationOnScroll>
                <div id='downdown' className='absolute bottom-8 bg-gray-700 p-2 hover:scale-110 transition-all rounded hover:cursor-pointer' onClick={() => {
                    const hDiv = document.getElementById('downdown')

                    window.scrollTo({ top: (hDiv.clientHeight + 500), behavior: 'smooth' })
                }}>
                    <KeyboardArrowDownIcon fontSize='large' className='text-red-600 hover:text-white transition-all' />
                </div>
            </div>
            <div className='m-10 laptop:mx-3'>
                <p className='text-gray-600  rounded py-4 px-6 mb-4'>
                    GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. expose dans cette section la Politique de Confidentialité d’entreprise sur les informations à caractère personnel que l’utilisateur peut fournir lors de la visite de notre site Web. La politique de confidentialité de  GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. est régie par la législation en vigueur en matière de protection des données à caractère personnel et la loi sur les services de la société de l’information et du commerce électronique.
                </p>
                <p className='font-bold ml-10 text-gray-600 laptop:mr-5' style={{ fontWeight: 'bold' }}>
                    1. Utilisation et traitement des données à caractère personnel
                </p>
                <br />
                <p className='text-gray-600 mx-10'>
                    Conformément aux dispositions de la loi organique 15/1999, du 13 décembre, sur la protection des données à caractère personnel (ci-après LOPD),  GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. vous informe que les données à caractère personnel par vous. fournis, ainsi que les données accessibles à la suite de leur navigation, seront incorporés dans les fichiers automatisés de  GLOBAL CORPORATION BUSINESS LAWYERS S.L.P.
                    <br />
                    GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. peut recueillir des informations auprès des utilisateurs via ce site Web par l’un des moyens suivants :
                </p>
                <br />
                <p className='text-gray-600 mx-10'>
                    - Informations fournies par l’utilisateur lui-même via les adresses de contact (e-mail).
                    <br />
                    -  Processus d’enregistrement pour certains services fournis sur le site Web.
                </p>
                <br />

                {/* <br /> */}
                <p className='text-gray-600 px-6'>
                    Les informations personnelles d’identification seront utilisées exclusivement pour répondre à vos demandes et pour vous fournir des informations sur  GLOBAL CORPORATION BUSINESS LAWYERS S.L.P.
                    <br />
                    À son tour, il peut être utilisé à d’autres fins, mais à condition qu’il soit préalablement sollicité et que son consentement exprès et spécifique soit obtenu.
                    <br />
                    Les données que vous fournissez seront utilisées exclusivement pour vous fournir des services d’information dans le domaine pour lequel elles ont été demandées, en permettant la mise à jour des données d’enregistrement pour le cas où vous ne souhaitez pas recevoir d’autres informations ou modifier le contenu de celles-ci.
                    <br />
                    GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. traite les informations personnelles dans le strict respect de la législation en matière de Données Personnelles, en particulier de la Loi Organique 15/1999, de Protection des Données à Caractère Personnel, et du RD 1720/2007.

                    <br />
                    En ce sens, toutes les données personnelles collectées seront intégrées dans les fichiers enregistrés dans le Registre général de la protection des données au nom de  GLOBAL CORPORATION BUSINESS LAWYERS S.L.P., en respectant toutes les obligations établies, notamment en ce qui concerne le respect des principes d’information et de consentement.
                    <br />
                    En outre,  GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. a mis en place, conformément au Règlement sur les mesures de sécurité, des politiques et des procédures de sûreté et d’exploitation pour protéger vos informations personnelles contre la perte, l’utilisation abusive, la modification ou la destruction involontaire, tout en préservant la confidentialité.

                    <br />
                    Dans les cas où il est nécessaire de remplir un formulaire (inscription à des séminaires ou événements), ou d’envoyer un e-mail (contacter :  contact@gcblawyers.com) et cliquer sur le bouton d’envoyer, sa réalisation impliquera nécessairement qu’il a été informé (en vertu de l’article 5 LOPD) et, le cas échéant, a donné son consentement (en vertu de l’article 6 LOPD) au contenu de la présente politique de confidentialité.

                    <br />
                    Ainsi, chaque fois que  GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. vous demande des données à caractère personnel, il inclura la clause légale correspondante et un lien (lien) lié à la présente Politique afin de vous faire partager vos droits et obligations établis dans la LOPD et LSSICE.
                </p>
                <br />
                <br />
                <p className='font-bold ml-10 text-gray-600 laptop:mr-5' style={{ fontWeight: 'bold' }}>
                    2. Exercice des droits : accès, rectification, annulation et opposition
                </p>
                <br />
                <p className='text-gray-600 mx-10'>
                    Les personnes physiques qui ont fourni leurs données à  GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. 
                    pourront s’adresser à elle, en sa qualité de responsable du fichier, afin de pouvoir exercer gratuitement leurs droits d’accès, 
                    rectification, annulation et opposition concernant les données intégrées dans vos fichiers. Vu le caractère confidentiel de l’information, 
                    vous. ne pourra pas exercer ses droits par téléphone, car ce moyen ne permet pas de prouver son identité en tant que titulaire des données enregistrées.
                    
                    {/* L’intéressé pourra exercer ses droits par une communication écrite adressée à GLOBAL CORPORATION BUSINESS LAWYERS S.L.P.. à l’adresse suivante : 
                    ATT : Responsable LOPD. Paseo de la Castellana nº 81, C.P. 28046 (Madrid), avec la référence suivante dans sa lettre 
                    : « Exercice des droits » (avec sa demande écrite et signée, il devra accréditer sa personnalité en apportant une photocopie de son D.N.I.). */}

                    L’intéressé pourra exercer ses droits par une communication écrite adressée à GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. à l’adresse suivante : 
                    ATT : Responsable LOPD. Calle Vistalegre 46, 28270 Colmenarejo-Madrid, avec la référence suivante dans sa lettre 
                    : « Exercice des droits » (avec sa demande écrite et signée, il devra accréditer sa personnalité en apportant une photocopie de son D.N.I.).
                </p>
                <br />
                <br />
                <p className='font-bold ml-10 text-gray-600 laptop:mr-5' style={{ fontWeight: 'bold' }}>
                    3. Modification de la politique de confidentialité
                </p>
                <br />
                <p className='text-gray-600 mx-10'>
                    GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. se réserve le droit de modifier sa Politique de Confidentialité, selon ses propres critères, ou motivée par un changement doctrinal de l’Agence Espagnole de Protection
                    <br />
                    des Données, législative ou jurisprudentielle. Toute modification de la Politique de confidentialité sera publiée au moins dix jours avant son application effective. L’utilisation du Web après ces changements impliquera l’acceptation expresse de ceux-ci.
                </p>
            </div>
            <br />
            <br />
        </div>
    )
}

export default Privacy