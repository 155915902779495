import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
// import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link, NavLink } from 'react-router-dom';
import useStore from '../../store/storeGlobal';
// import { deepOrange } from '@mui/material/colors';
// import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useCookies } from 'react-cookie';

const pages = [
	{ title: 'Accueil', route: '' },
	{ title: 'Services', route: 'Serv' },
	{ title: 'Valeurs', route: 'Vals' },
	{ title: 'Alliance', route: 'Alliance' },
	{ title: 'Contact', route: 'Contact' },
]
const pagesEN = [
	{ title: 'Home', route: '' },
	{ title: 'Services', route: 'Serv' },
	{ title: 'Values', route: 'Vals' },
	{ title: 'Alliance', route: 'Alliance' },
	{ title: 'Contact', route: 'Contact' },
]
const pagesES = [
	{ title: 'INICIO', route: '' },
	{ title: 'SERVICIOS', route: 'Serv' },
	{ title: 'VALORES', route: 'Vals' },
	{ title: 'ALIANZA', route: 'Alliance' },
	{ title: 'Contacto', route: 'Contact' },
]
//  'Services', 'Valeurs', 'Alliances'];
// const settings = ['FR', 'EN', 'ES'];
const settings = [
	{ lg: 'FR', img: 'fr', tx: 'Français' },
	{ lg: 'EN', img: 'gb', tx: 'English' },
	{ lg: 'ES', img: 'es', tx: 'Español' },
];

const ResponsiveAppBar = ({ HeightHeaderImg }) => {
	const globalLng = useStore(state => state.Languge)
	const [anchorElNav, setAnchorElNav] = React.useState(null);
	const [ActiveBar, setActiveBar] = React.useState('');
	const [pagesHere, setpagesHere] = React.useState([]);
	const [anchorElUser, setAnchorElUser] = React.useState(null);
	const [colorChange, setColorchange] = React.useState(false);
	const [topscroll, setTop] = React.useState(false);
	const [cookies, setCookie] = useCookies(['lng']);

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	React.useEffect(() => {
		window.addEventListener('scroll', () => {
			if (window.scrollY >= (HeightHeaderImg - 50)) {
				setColorchange(true);
				setTop(true);
			}
			else {
				setColorchange(false);
				setTop(false);
			}
		})
	}, [HeightHeaderImg])
	React.useEffect(() => {
		if (globalLng === 'EN') {
			setpagesHere(pagesEN)
		}
		if (globalLng === 'ES') {
			setpagesHere(pagesES)
		}
		if (globalLng === 'FR') {
			setpagesHere(pages)
		}
		// const aa 
		// const a = window.scrollTo({ top: 0, behavior: 'smooth' });
	}, [globalLng])
	return (
		<div className={`fixed top-0 w-full z-50`}>
			{/* <AppBar position="fixed" className={colorChange ? 'bg-black py-2 z-50' : 'transparent py-2'}> */}
			<AppBar position="fixed" className={colorChange ? ' py-1 z-50 border-b border-b-red-600 px-2' : ' py-1 z-50 border-b border-b-red-600 px-2'} style={{ backgroundColor: '#000' }}>
				<Container maxWidth="xl">
					<Toolbar disableGutters>
						{/* {colorChange && ( */}
						<Link to='/' className='px-0'>
							<Typography
								variant="h6"
								noWrap
								component="div"
								sx={{ mr: 2, display: { xs: 'none', md: 'flex' }, justifyContent: 'end' }}
							>
								{/* <span className='text-white'>GLOBAL CORPORATION BUSINESS LAWYERS</span> */}
								<img src='/img/white-no-background-logo.png' alt='logo' width={170} />
							</Typography>
						</Link>
						{/* )} */}
						{/* <Typography
							variant="h6"
							noWrap
							component="div"
							sx={{flexGrow: 1,display: {xs: 'flex',md: 'none'}}}
						>
						</Typography> */}
						<Link to='/' className='px-0'>
							<Typography
								variant="h6"
								noWrap
								component="div"
								sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
							>
								<img src='/img/white-no-background-logo.png' alt='logo' width={150} />
							</Typography>
						</Link>
						<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'end' }}>
							<button
								// size="large"
								// aria-label="account of current user"
								// aria-controls="menu-appbar"
								// aria-haspopup="true"
								className='hover:scale-110 transition-all'
								onClick={handleOpenNavMenu}
							// color='error'
							// className='text-white'
							// htmlColor='white'
							>
								<MenuIcon fontSize='large' />
							</button>
							<Menu
								id="menu-appbar"
								anchorEl={anchorElNav}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
								open={Boolean(anchorElNav)}
								onClose={handleCloseNavMenu}
								sx={{
									display: { xs: 'block', md: 'none' },
								}}
							>
								{pagesHere.map((page) => (
									<MenuItem key={page.title} onClick={handleCloseNavMenu} sx={{ borderBottom: 1, borderBottomColor: '#d0cece', my: 0, py: 0, px: 0, mx: 0 }} >
										<Link to={`/${page.route}`} className='px-4'>
											<Typography textAlign="center" sx={{ my: 1, mx: 2, }}>
												{page.title}
											</Typography>
										</Link>
									</MenuItem>
								))}
							</Menu>
						</Box>
						<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'end' }}>
							{pagesHere.map((page) => (
								<NavLink to={`/${page.route}`} key={page.title} onClick={() => {
									setActiveBar(page.title)
								}} className='mx-0'>
									<Button
										onClick={handleCloseNavMenu}
										// sx={{ my: 0, mx: 2, color: ActiveBar === page.title ? '#e40f26' : 'white',border:ActiveBar === page.title ? 1 : 0, borderColor: ActiveBar === page.title ? '#e40f26' : '' }}
										sx={{ my: 0, mx: 2, color: 'white' }}
										className='hover:text-red-600'
									>
										{page.title}
									</Button>
								</NavLink>
							))}
						</Box>

						<Box sx={{ flexGrow: 0, ml:2, mb:1 }}>
							<Tooltip title="Languages">
								<IconButton onClick={handleOpenUserMenu} sx={{ p: 0, borderBottom: '1px solid white', borderRadius: 0 }}>
									{/* <Avatar sx={{ bgcolor: 'transparent', fontSize:'14px', border: '1px solid white'}}> */}
									<span className='text-red-500 text-base font-bold pr-1 '>{globalLng}</span>
									<img
										loading='eager'
										className='my-2'
										width='20'
										src={`https://flagcdn.com/w20/${globalLng === 'FR' ? 'fr' : globalLng === 'EN' ? 'gb' : 'es'}.png`}
										alt='Fr'
									/>
									{/* </Avatar> */}
									{/* <Avatar alt="Remy Sharp" text='globalLng' /> */}
								</IconButton>
							</Tooltip>
							<Menu
								sx={{ mt: '45px' }}
								id="menu-appbar"
								anchorEl={anchorElUser}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={Boolean(anchorElUser)}
								onClose={handleCloseUserMenu}
							>
								{settings.map((setting) => (
									<MenuItem key={setting.lg} onClick={() => {
										useStore.setState({ Languge: setting.lg })
										setCookie('langague', setting.lg);
										handleCloseUserMenu()	
									}}
									>
										<Typography textAlign="center">{setting.tx}</Typography>
										<img
											loading='eager'
											className='ml-3 mr-1 my-2'
											width='20'
											src={`https://flagcdn.com/w20/${setting.img}.png`}
											alt='Fr'
										/>
									</MenuItem>
								))}
							</Menu>
						</Box>
					</Toolbar>
				</Container>
			</AppBar >
			{topscroll && (
				<div
					onClick={() => {
						window.scrollTo({ top: 0, behavior: 'smooth' });
					}}
					className='fixed bottom-10 right-4 rounded-[8px] px-3 py-2
					bg-gray-700
					hover:scale-110 hover:cursor-pointer transition-all'>
					{/* <ArrowUpwardIcon color='white' fontSize='large' /> */}
					<KeyboardArrowUpIcon htmlColor='white' fontSize='small' />

				</div>
			)}
		</div >
	);
};
export default ResponsiveAppBar;
