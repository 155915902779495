import React from 'react'
import { Link } from 'react-router-dom'
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const FooterEN = () => {
	return (
		<div className='pt-10 pb-0 px-6 laptop:pt-4 text-white' style={{ backgroundColor: '#616266' }}>
			<div className='flex items-start laptop:grid laptop:justify-center laptop:grid-cols-1'>
				<div className='flex-[23%] text-left laptop:text-center mt-14'>
					<img src='/img/white-no-background-logo.png' alt='logo' width={'80%'} className='laptop:w-[200px] laptop:mx-auto laptop:mb-9' />
				</div>
				<div className='flex-[14%] text-left pl-10 laptop:pl-2'>
					<h1 className='text-xl text-white text-left font-bold mb-7 laptop:mb-2'>PLAN</h1>
					<p className='mb-3 text-sm'><Link to=''>Home</Link></p>
					<p className='mb-3 text-sm'><Link to='Serv'>Services</Link></p>
					<p className='mb-3 text-sm'><Link to='Vals'>Values</Link></p>
					<p className='mb-3 text-sm'><Link to='Alliance'>Alliance</Link></p>
					<p className='mb-3 text-sm laptop:mb-9'><Link to='Contact'>Contact</Link></p>

				</div>
				<div className='flex-[26%] text-left  pl-4 laptop:pl-2 '>
					<h1 className='text-xl text-white text-left font-bold mb-7 laptop:mb-2'>LEGAL MENTIONS</h1>
					<p className='mb-3 text-sm'><Link to='Legal'>Legal notice</Link></p>
					<p className='mb-3 text-sm'><Link to='Priv'>Privacy policy</Link></p>
					<p className='mb-3 text-sm laptop:mb-9'><Link to='Sec'>Security policy</Link></p>
				</div>
				<div className='flex-[35%] laptop:pl-2 '>
					<h1 className='text-xl text-white  text-left font-bold mb-7 laptop:mb-2'>CONTACT</h1>
					<p className='mb-3 text-base'></p>
					<p className='mb-3 text-sm laptop:text-left '>Calle Vistalegre 46, 28270 Colmenarejo  (Madrid), Spain</p>
					<p className='mb-3 text-sm'>Phone  : +34 615 927 820</p>
					<p className='mb-3 text-sm laptop:mb-9 laptop:text-left'>Email  :
						<a href='mailto:contact@gcblawyers.com' className='ml-3'>  contact@gcblawyers.com</a>
					</p>
					{/* <hr className='my-3'/> */}
					<a href='https://www.linkedin.com/company/global-corporation-business-lawyers/?viewAsMember=true' target='_blank' rel="noreferrer">
						<LinkedInIcon className='hover:scale-110 hover:cursor-pointer transition-all hover:text-blue-600' fontSize='large' />
					</a>
				</div>
			</div>
			<hr className='mt-5' />
			<h1 className='text-md text-white text-center py-5'>Copyright © GC Business Lawyers. 2022</h1>
		</div>
	)
}

export default FooterEN