import React from 'react'
import RecipeReviewCard from '../utilsPages/Card'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import servicesDomainsFr from '../utilsData/constants';
import { Icon } from '@mui/material';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
const Services = () => {
	const listEl = [
		'Droit des affaires internationales',
		'Droit fiscal et fiscalité internationale',
		'Droit commercial et droit de la concurrence',
		'Propriété intellectuelle et industrielle',
		'Droit des sociétés',
		'Droit pénal international des affaires',
		'Corporate Finance',
		'Transactions, fusions et acquisitions',
		'Gouvernement d’entreprise et Corporate Compliance',
		'Recouvrement de créances internationales',
	]
	return (
		<div>
			<div id='BgImage' className='h-[100vh] bg-black-33 bg-top bg-cover pl-5 flex pt-6 justify-center items-center relative' >
				<AnimationOnScroll animateIn="animate__heartBeat" animateOnce>
					{/* <h1 className='text-gr  font-bold mb-0 text-center text-red-600 px-3 py-6'>
					</h1> */}
					{/* La forme d'art la plus fascinante <br/> c’est être les meilleurs <br/> grâce à nous en affaires */}
					<h1 className='text-white text-gr font-bold text-center bg-tr uppercase laptop:text-3xl'>
						SERVICES
					</h1>
				</AnimationOnScroll>
				<div id='downdown' className='absolute bottom-8 bg-gray-700 p-2 hover:scale-110 transition-all hover:cursor-pointer rounded' onClick={() => {
					const hDiv = document.getElementById('downdown')

					window.scrollTo({ top: (hDiv.clientHeight + 500), behavior: 'smooth' })
				}}>
					<KeyboardArrowDownIcon fontSize='large' className='text-red-600 hover:text-white transition-all' />
				</div>
			</div>
			{/* <h1 className='text-1xl  font-bold mb-0 text-center bg-blue-700 text-white px-3 py-6'>
				SERVICES
			</h1> */}
			<AnimationOnScroll animateIn="animate__backInLeft" animateOnce >
				<div className='py-20 mx-10 laptop:mx-5'>
					<h1 className='text-2xl rounded-r-2xl font-bold mb-0 text-center px-3 py-6'>
						Notre expérience et savoir-faire pour votre déploiement à l’international
					</h1>
					<p className='px-8 py-4 border-l-2 border-l-red-700 text-justify'>
						L’accompagnement juridique de <b>GC BUSINESS LAWYERS</b> permet d’assister les sociétés dans leur implantation internationale après une analyse exhaustive de l’environnement, afin de bien identifier et évaluer les garanties juridiques et réglementaires nécessaires pour la sécurité de tout investissement international.
						<br />
						<br />
						À travers son partenariat avec l’<b>ALLIANCE</b>, le cabinet d’avocats <b>GC BUSINESS LAWYERS</b> n’identifie pas seulement les marchés qui conviennent aux services et produits des sociétés qu’il conseille juridiquement pour leur déploiement à l’international, mais également il veille à ce que tous les processus juridiques et réglementaires spécifiques de chaque pays soient respectés, avec des cultures, des langues et des schémas de travail très différents les uns des autres pour faire de cette diversité un point fort de développement pour ses clients.
					</p>
				</div>
			</AnimationOnScroll>
			<div className=' py-20 px-10 bg-black text-white laptop:px-5'>
				<h1 className='text-2xl rounded-r-2xl font-bold mb-0 text-center px-3 py-6'>
					Domaines de compétences
				</h1>
				<p className='px-8 py-4 border-l-2 border-l-red-700 text-justify'>
					L'expérience de <b>GC BUSINESS LAWYERS</b> et de ses partenaires internationaux lui permet d'offrir des services spécialisés dans divers domaines.
					<br />
					<br />
				</p>
				<ul className='ul-gclawyers border-l-2 border-l-red-700'>
					{listEl.map((el, ind) => (
						<AnimationOnScroll key={el} delay={ind / 1.5} animateIn="animate__fadeInDown" animateOnce>
							<li>{el}</li>
						</AnimationOnScroll>
					))}
				</ul>
			</div>
			<div>
				<div className=' grid 	grid-cols-2 gap-2 py-14 px-10 laptop:px-4 laptop:grid-cols-1'>
					{servicesDomainsFr.map((el, ind) => (
						<AnimationOnScroll key={el.title} delay={ind / 1.5} animateIn="animate__fadeInDown" animateOnce>
							<div className='pt-8 px-4 border rounded m-2 h-[20rem] laptop:h-auto'>
								{/* <Icon baseClassName="fas" className="fa-plus-circle" color="info" /> */}
								<div className='flex justify-center items-center mb-5'>
									<AlignHorizontalLeftIcon />
									<h1 className='font-bold m-0 ml-4 text-xl'>{el.title}</h1>
								</div>
								<div className=''>
									{el.text.map((text, ind) => (
										<p key={ind} className='py-2 pl-4 text-sm'dangerouslySetInnerHTML={{ __html: text }} />
									))}
								</div>
							</div>
						</AnimationOnScroll>
					))}
				</div>
				{/* <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce>
					<div className='flex justify-center items-center py-10 px-10 pt-2'>
						<div className='py-20 px-4 border flex flex-col justify-center items-center rounded m-2'>
							<div className='flex justify-center items-center mb-4'>
								<AlignHorizontalLeftIcon />
								<h1 className='font-bold m-0 ml-4'>Recouvrement de créances internationales</h1>
							</div>
							<div className=''>
								<p className='py-2 pl-4 '>
									GC BUSINESS LAWYERS et son réseau à l’international apportent à leurs sociétés clientes une connaissance de la législation de la plupart des pays internationaux pour récupérer leurs créances auprès de leurs clients internationaux. Elle s’engage à donner dans de brefs délais après étude du dossier une réponse sur la possibilité de recouvrir les créances à court terme.
								</p>
							</div>
						</div>
					</div>
				</AnimationOnScroll> */}
			</div>
		</div>
	)
}

export default Services