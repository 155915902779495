import React from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import emailjs from '@emailjs/browser';
import { init } from '@emailjs/browser';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Alert } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import ReCAPTCHA from "react-google-recaptcha";


const Contact = () => {
    init("_69HMOxc009hZnd6t");
    const form = React.useRef()
    const [alert, setalert] = React.useState(false)
    const [verified, setverified] = React.useState(false)
    const [sending, setsending] = React.useState(false)
    const [alertType, setalertType] = React.useState('')
    const [data, setdata] = React.useState({
        emailaccount: '',
        object: '',
        message: '',
    })
    const HandleChangeInputs = (f, v) => {
        setdata({
            ...data,
            [f]: v,
        })
    }
    function onChange(value) {
        setverified(true)
    }
    const sendMail = (e) => {
        e.preventDefault();
        setalert(true)
        setsending(true)
        // console.log('🚀 => form.current: : ', form.current)
        emailjs.sendForm('service_qgteyjs', 'template_b8hbc6p', form.current)
            .then((result) => {
                // console.log('Res : ', result)
                if (result.text === 'OK') {
                    setalertType('ok')
                    setdata({
                        emailaccount: '',
                        object: '',
                        message: '',
                    })
                } else {
                    setalertType('no')
                }
            }, (error) => {
                setalertType('no')
                console.log('error: ', error.text)
            });
        setsending(false)
        setTimeout(() => {
            setalert(false)
        }, 5000);
    }
    return (
        <div className='relative'>
            <AnimationOnScroll animateIn="animate__fadeInBottomLeft" animateOnce duration={0.5}>
                <div id='BgImage' className='h-[100vh]  bg-top bg-cover pl-5 flex pt-6 justify-end items-center bg-black-op-11 px-16 laptop:justify-center laptop:px-0   ' >
                    {/* <img src='/img/white-no-background.png' alt='logo' width={'45%'} /> */}

                    <div className='bg-white p-5 mx-10 mt-10 rounded laptop:w-[300px]'>
                        <h1 className='text-2xl font-bold text-center text-gray-900 mb-6 laptop:text-xl'>
                        CONTACT US
                        </h1>
                        {/* <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '30rem' },
                        }}
                        noValidate
                        autoComplete="off"
                    > */}
                        <form ref={form} onSubmit={sendMail} className='w-[20rem] text-center laptop:text-left space-y-4'>

                            <TextField id="emailaccount" label="Email" variant="outlined" name='emailaccount' size="small" value={data.emailaccount} type='email' className='w-full laptop:w-[260px]' onChange={(e) => {
                                HandleChangeInputs(e.target.name, e.target.value)
                            }} />
                            <TextField id="object" label="Objet" variant="outlined" name='object' size="small" value={data.object} className='w-full laptop:w-[260px] mb-5' onChange={(e) => {
                                HandleChangeInputs(e.target.name, e.target.value)
                            }} />
                            <TextField id="message" label="Message" variant="outlined" name='message' size="small" value={data.message} multiline placeholder="..." className='w-full laptop:w-[260px]' rows={4} onChange={(e) => {
                                HandleChangeInputs(e.target.name, e.target.value)
                            }} />

                            <div className='text-center w-full laptop:w-[260px] '>
                                <ReCAPTCHA
                                    sitekey="6LfQkywfAAAAABjvUmBk2oTiWElqdpMkQDQEZ7KP"
                                    onChange={onChange}
                                    className='text-center flex justify-center mb-2 laptop:scale-90'
                                    // theme='dark'
                                />
                                <Button variant="contained" endIcon={sending ? <CachedIcon className='animate-spin' /> : <SendIcon />} color='primary'
                                    // onClick={sendMail}
                                    type='submit'
                                    // disabled={!verified}
                                    disabled={!data.emailaccount || !data.object || !data.message || !verified || !verified}
                                    className='max-w-[190px] text-center rounded-2xl'>
                                    Envoyer
                                </Button>
                            </div>
                        </form>
                        {/* </Box> */}

                    </div>
                </div>
            </AnimationOnScroll>
            <div className='fixed top-20 left-8'>
                {alert && alertType === 'ok' && (
                    <Alert severity="success">The email was sent successfully</Alert>
                )}
                {alert && alertType === 'no' && (
                    <Alert severity="error">The email was not sent, Error! </Alert>
                )}
            </div>
        </div >
    )
}

export default Contact