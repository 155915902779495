import React from 'react'
import useStore from '../../store/storeGlobal'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const Alliance = () => {
  // const state = useStore()

  return (
    <div>
      <div id='BgImage' className='h-[100vh] bg-black-op-55 bg-top bg-cover pl-5 flex pt-6 justify-center items-center relative' >
        <AnimationOnScroll animateIn="animate__heartBeat">
          {/* <h1 className='text-gr  font-bold mb-0 text-center text-red-600 px-3 py-6'>
          </h1>
						SERVICES */}
          <h1 className='text-white text-gr font-bold text-center bg-tr uppercase laptop:text-3xl'>
            ALLIANCE
          </h1>
          {/* <img src='/img/white-no-background.png' alt='logo' width={'45%'} /> */}
        </AnimationOnScroll>
        <div id='downdown' className='absolute bottom-8 bg-gray-700 p-2 hover:scale-110 transition-all rounded hover:cursor-pointer' onClick={() => {
          const hDiv = document.getElementById('downdown')

          window.scrollTo({ top: (hDiv.clientHeight + 500), behavior: 'smooth' })
        }}>
          <KeyboardArrowDownIcon fontSize='large' className='text-red-600 hover:text-white transition-all' />
        </div>
      </div>
      {/* <h1 className='text-1xl font-bold mb-0 text-center bg-blue-700 text-white px-3 py-6'>
      </h1> */}
      <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce >
        <div className='py-20 px-10 laptop:px-6'>
          <h1 className='font-bold text-2xl'>Un réseau international post moderne</h1>
          <p className='text-[15px]'>
            <br />
            L’ALLIANCE est un réseau international post moderne constitué de partenaires prescripteurs et stratégiques dans divers secteurs fournissant à l’écosystème diverses opportunités pour l’accès aux financements, à des modèles d’affaires innovants et de nouveaux marchés à l’international.

            <br />
            <br />
            L’ALLIANCE s’articule autour d’un réseau international de sociétés hautement spécialisées avec une grande expérience dans le conseil et l’ingénierie dans divers domaines juridique, fiscal, financier, technique et technologique. C’est à travers la présence des filiales à l’international (Europe, Afrique, Moyen-Orient, Asie et Amérique) des sociétés de l’ALLIANCE que les partenaires stratégiques ainsi que les entreprises clientes innovantes et durables peuvent accéder à de nouvelles opportunités de collaboration assurant des accès à de nouveaux marchés.
            <br />
            <br />
            L’ALLIANCE est dirigée par un comité de coordination formé par une équipe de professionnels qualifiés avec BALDER Business Intelligence comme coordinateur et représentant officiel.
          </p>
        </div>
      </AnimationOnScroll>
      <div className='py-20 px-10 bg-black text-white text-left laptop:px-6 '>
        <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce >
          <h1 className='font-bold text-2xl'>Un affermissement sur le marché de l’investissement international </h1>
          <p className='text-white text-[15px]'>
            <br />
            Selon le Moniteur des tendances d’investissement de la Conférence des Nations Unies pour le Commerce et le Développement (CNUCED), en 2021 les flux mondiaux d'investissements directs étrangers (IDE) ont enregistré un fort rebond. Ils avaient augmenté de 77 % pour atteindre quelques 1 650 milliards de dollars, à comparer avec les 929 milliards de dollars en 2020, dépassant ainsi leur niveau d'avant la COVID-19. Bien que la reprise des flux d'investissement vers les pays en développement est en hausse, la stagnation des investissements nouveaux dans les pays les moins avancés, notamment dans les industries essentielles au développement des capacités de production et des secteurs clés des objectifs de développement durable (ODD) - tels que l'électricité, l'alimentation ou la santé – reste préoccupante. Le rapport de la CNUCED révèle une forte confiance des investisseurs dans les secteurs des infrastructures, soutenue par des conditions de financement à long terme favorables, ainsi que des plans de relance et des programmes d'investissement à l'étranger. Pour 2022, ce rapport indique des perspectives positives pour les IDE.

            <br />
            <br />
            En ce qui concerne le capital investissement, il a connu en 2021 une année exceptionnelle avec des investissements record, avec une acquisition de plus de 12.300 entreprises pour un montant dépassant les 1.100 milliards de dollars, avec un stock de capital prêt pour l’investissement qui a atteint les 3.400 milliards de dollars. Le marché mondial des fusions-acquisitions a connu un énorme essor, dû aux injections massives de liquidité des banques centrales pour atténuer les effets de la crise sanitaire qui a démarré en 2020 avec le COVID. Mais c’est surtout la prise en compte des enjeux ESG qui ont placé la responsabilité sociétale des entreprises au sein des décisions d’investissements, comme moteur de création de valeur.
            <br />
            <br />
          </p>
        </AnimationOnScroll>
      </div>
      <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce >
        <div className='py-20 px-10 laptop:px-6'>
          <h1 className='font-bold text-2xl'>Le cas particulier du leadership africain</h1>
          <p className='text-[15px]'>
            <br />
            On assiste à un affermissement du leadership africain depuis la naissance de l’Union Africaine en 2002 à l’initiative d’États pivots (Algérie, Nigeria, Afrique du Sud, Éthiopie) d’où la décision de BALDER Business Intelligence d’implanter une Direction Régionale dans ce continent.
            <br />
            <br />
            Le développement d’écosystèmes commerciaux régionaux a alors tout son sens si depuis ce pôle sont ciblés les marchés africains pour promouvoir des investissements directs étrangers (IDE) dans divers secteurs d’investissements.
          </p>
        </div>
      </AnimationOnScroll>
      <div className='py-20 px-10 bg-black text-white text-left laptop:px-6 '>
        <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce >
          <h1 className='font-bold text-2xl'>Le déploiement de l'ALLIANCE</h1>
          <p className='text-white text-[15px]'>
            <br />
            C’est dans ce cadre qu’une nouvelle Alliance internationale de promotion des investissements directs internationaux par BALDER Business Intelligence à travers l’identification de partenaires stratégiques clés a été mise en place pour créer des conditions favorables aux investisseurs (cabinets internationaux d’avocats d’affaires, chambres de commerces et organismes professionnels, entités financières et fonds d’investissement et réseau d’influence de sociétés d’intelligence économique) et profiter de ce prometteur environnement.
            <br />
            <br />
            Disposant d’un large réseau de sociétés partenaires au niveau international avec lesquelles BALDER Business Intelligence a signé des accords de partenariat, elle a la possibilité d’offrir à ses clients des connaissances économiques, commerciales, juridiques et fiscales accrues dans l’unique but de leur assurer un total succès. La société met tout en œuvre pour que les différents processus juridiques et réglementaires propres à chaque pays, à chaque culture et à chaque langue soient scrupuleusement respectés et honorés en mettant en place des schémas de travail adaptés et individuels.            <br />
            <br />
          </p>
        </AnimationOnScroll>
      </div>
      {/* <div className='py-20 px-10 bg-black text-white text-left laptop:px-6 '>
        <h1 className='font-bold text-2xl'>Un affermissement sur le marché de l’investissement international</h1>
        <p className='text-white text-[15px]'>
          <br />
          On assiste à un affermissement du leadership africain depuis la naissance de l’Union Africaine en 2002 à l’initiative d’États pivots (Algérie, Nigeria, Afrique du Sud, Éthiopie) d’où la décision de BALDER Business Intelligence d’implanter son HUB régional pour l’Afrique en Algérie, pays pivot avec un énorme potentiel de développement dans les cinq prochaines années avec la création d’une filiale dans la ville d’Oran, ville avec un grand patrimoine historique en relation avec l’Espagne, son HUB régional pour l’Europe.
          <br />
          <br />
          Les relations entre l’Afrique et l’Europe restent centrées sur les thèmes du développement et de la sécurité. Cependant, le maintien d’une plus grande croissance économique en Afrique en raison d’un impact plus limité de la crise sanitaire provoquée par le COVID-19 et les prévisions d’une croissance moins ferme en Europe devraient inciter les entreprises européennes à regarder de plus près les opportunités du sud (Rive-Sud de la Méditerranée et Afrique).
          <br />
          <br />
          Le développement d’écosystèmes commerciaux régionaux a alors tout son sens si depuis ce pôle central sont ciblés les marchés du nord de l’Afrique avec l’Algérie comme centre névralgique pour promouvoir des investissements directs internationaux en même temps dans tout le continent africain dans divers secteurs d’investissements.
          <br />
          <br />
          C’est dans ce cadre qu’une nouvelle Alliance Afrique-Europe de promotion des investissements directs internationaux par BALDER Business Intelligence à travers l’identification de partenaires stratégiques clés a été mise en place pour créer des conditions favorables aux investisseurs (cabinets internationaux d’avocats d’affaires, chambres de commerces et organismes professionnels, entités financières et fonds d’investissement et réseau d’influence de sociétés d’intelligence économique).
          <br />
          <br />
          Disposant d’un large réseau de sociétés partenaires au niveau national et post frontières avec lesquelles BALDER Business Intelligence a signé des accords de partenariat et elle a la possibilité d’offrir à ses clients des connaissances économiques, commerciales, juridiques et fiscales accrues dans l’unique but de leur assurer un total succès. La société met tout en œuvre pour que les différents processus juridiques et réglementaires propres à chaque pays, à chaque culture et à chaque langue soient scrupuleusement respectés et honorés en mettant en place des schémas de travail adaptés et individuels.
        </p>
      </div> */}
      <div className='py-20 px-10 pt-12 laptop:px-6'>
        <h1 className='font-bold text-4xl text-center mb-16 '>Les membres de l’ALLIANCE</h1>
        {/* <hr className='my-12' /> */}
        <div className='flex h-[20rem] border rounded-xl mb-6 laptop:flex-col-reverse   laptop:justify-center laptop:items-start laptop:py-10 laptop:h-auto '>
          <div className='w-1/2 flex flex-col justify-center items-center mx-8 laptop:mx-1 laptop:px-4  laptop:w-full'>
            <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce >
              <a href='https://balderbi.com/' target='_blank' rel="noreferrer">
                <h1 className='text-xl font-bold mb-4 laptop:text-base '>BALDER Business Intelligence</h1>
              </a>
              <p>Société de développement de partenariats internationaux d’affaires et promotion de l’Investissement Direct Étranger IDE</p>
            </AnimationOnScroll>
          </div>
          <div className='w-1/2 mx-8 flex flex-col justify-center items-center laptop:mb-8 laptop:w-full laptop:mx-2'>
            <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce >
              <a href='https://balderbi.com/' target='_blank' rel="noreferrer">
                <img src='/img/Balder_logo_medium_resolution.png' alt='logo' className='w-[300px] laptop:w-[230px] hover:scale-90 transition-all' />
              </a>
            </AnimationOnScroll>
          </div>
        </div>
        {/* <hr className='my-12' /> */}
        <div className='flex h-[20rem] border rounded-xl mb-6 laptop:flex-col  laptop:justify-center laptop:items-start laptop:py-10 laptop:h-auto'>
          {/* <div className='flex h-[20rem] border rounded-xl mb-6'> */}
          <div className='w-1/2 mx-8 flex flex-col justify-center items-center laptop:mb-8 laptop:w-full laptop:mx-2'>
            <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce>
              <a href='http://nablanalytics.com/' target='_blank' rel="noreferrer">
                <img src='/img/nablanalytics.png' alt='logo' className='w-[300px] laptop:w-[230px] hover:scale-90 transition-all' />
              </a>
            </AnimationOnScroll>
          </div>
          <div className='w-1/2 flex flex-col justify-center items-center mx-8 laptop:mx-1 laptop:px-4  laptop:w-full'>
            {/* <div className='w-1/2 flex flex-col justify-center items-center mx-8'> */}
            <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce >
              <a href='http://nablanalytics.com/' target='_blank' rel="noreferrer">
                <h1 className='text-xl font-bold mb-4 laptop:text-lg'>NABLA Analytics</h1>
              </a>
              <p>Société internationale d’intelligence économique qui concentre ses activités sur les recommandations stratégiques et l’étude  des marchés de l’investissement dans divers secteurs en faisant appel à  sa plateforme d'intelligence artificielle  TWELVE Economic Engineering
              </p>
            </AnimationOnScroll>
          </div>
        </div>
        {/* <hr className='my-12' /> */}
        <div className='flex h-[20rem] border rounded-xl mb-6 laptop:flex-col-reverse   laptop:justify-center laptop:items-start laptop:py-10 laptop:h-auto '>
          {/* <div className='flex h-[20rem] border rounded-xl mb-6'> */}
          <div className='w-1/2 flex flex-col justify-center items-center mx-8 laptop:mx-1 laptop:px-4  laptop:w-full'>
            {/* <div className='w-1/2 flex flex-col justify-center items-center mx-8'> */}
            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce >
              <a href='http://balderbe.com/' target='_blank' rel="noreferrer">
                <h1 className='text-xl font-bold mb-4 laptop:text-lg'>BALDER Business Ecosystem</h1>
              </a>
              <p>
                Société internationale d’accélération des projets innovants, offrant un écosystème hybride de divers services aux entreprises dans des domaines variés allant de l’administration, les études, l’accompagnement juridique ainsi qu’une infrastructure de cloud computing  avec une interface permettant aux utilisateurs d'accéder à leurs ressources virtualisées à travers sa plateforme POLARIS Digital Solutions  spécialisée  dans la prescription de plusieurs solutions digitales innovantes et durables.
              </p>
            </AnimationOnScroll>
          </div>
          <div className='w-1/2 mx-8 flex flex-col justify-center items-center laptop:mb-8 laptop:w-full laptop:mx-2'>

            {/* <div className='w-1/2 mx-8 flex flex-col justify-center items-center'> */}
            <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce >
              <a href='http://balderbe.com/' target='_blank' rel="noreferrer">
                <img src='/img/bbe.png' alt='logo' className='w-[300px] laptop:w-[230px] hover:scale-90 transition-all' />
              </a>
            </AnimationOnScroll>
          </div>
        </div>
        <div className='flex h-[20rem] border rounded-xl mb-6 laptop:flex-col   laptop:justify-center laptop:items-start laptop:pt-6 py-10 laptop:h-auto'>
          <div className='w-1/2 mx-8 flex flex-col justify-center items-center laptop:mb-8 laptop:w-full laptop:mx-2'>
            <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce >
              <a href='https://gcblawyers.com/' target='_blank' rel="noreferrer">
                <img src='/img/GC_Black.png' alt='logo' className='w-[300px] laptop:w-[230px] hover:scale-90 transition-all' />
              </a>
            </AnimationOnScroll>
          </div>
          <div className='w-1/2 flex flex-col justify-center items-center mx-8 laptop:mx-1 laptop:px-4  laptop:w-full'>
            {/* <div className='w-1/2 flex flex-col justify-center items-center mx-8'> */}
            <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce >
              <a href='https://gcblawyers.com/' target='_blank' rel="noreferrer">
                <h1 className='text-xl font-bold mb-4 laptop:text-lg'>GC Business Lawyers </h1>
              </a>
              <p>
                Cabinet d’avocats d'affaires spécialisé, implanté en Europe, en Afrique et au Moyen-Orient grâce à un vaste réseau de cabinets d'avocats partenaires présents dans diverses régions du monde qui permettent aux entreprises d'être accompagnées dans leur implantation internationale après une analyse exhaustive de l'environnement commercial et d'affaires, afin d’identifier  les garanties légales nécessaires.
              </p>
            </AnimationOnScroll>
          </div>
        </div>
        {/* <hr className='my-12' /> */}
      </div>
    </div >
  )
}

export default Alliance