import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const Valeurs = () => {
	return (
		<div>
			<div id='BgImage' className='h-[100vh] bg-black-op-22 bg-top bg-cover pl-5 flex pt-6 justify-center items-center relative' >
				<AnimationOnScroll animateIn="animate__heartBeat" animateOnce>
					{/* <img src='/img/white-no-background.png' alt='logo' width={'45%'} /> */}
					{/* <h1 className='text-gr  font-bold mb-0 text-center text-red-600 px-3 py-6'>
					</h1> */}
					<h1 className='text-white text-gr font-bold text-center bg-tr uppercase laptop:text-3xl'>
						VALUES
					</h1>
				</AnimationOnScroll>
				<div id='downdown' className='absolute bottom-8 bg-gray-700 p-2 hover:scale-110 transition-all rounded hover:cursor-pointer' onClick={() => {
					const hDiv = document.getElementById('downdown')

					window.scrollTo({ top: (hDiv.clientHeight + 500), behavior: 'smooth' })
				}}>
					<KeyboardArrowDownIcon fontSize='large' className='text-red-600 hover:text-white transition-all' />
				</div>
			</div>
			<AnimationOnScroll animateIn="animate__zoomIn" animateOnce >

				<div className='py-20 mx-12 laptop:mx-8'>
					<p>
						<b>GC BUSINESS LAWYERS</b> constantly concentrates on activities and offers services which are oriented at all times on strong values which converge towards a privileged attention for the analysis and understanding of the needs of its clients.

					</p>
					<br />
					<p>
						The great experience and expertise of the professional teams of <b>GC BUSINESS LAWYERS</b> and its international network of legal partners is a permanent guarantee of the validity of the solutions for the development of international partnerships that it offers to clients.
					</p>
					<br />
					<p>
						In order to optimize resources to ensure sustainable development, <b>GC BUSINESS LAWYERS</b> relies, thanks to its membership in the ALLIANCE, on an Information System benefiting from the latest technologies using artificial intelligence for continuous improvement and efficiency in all of its activities.
					</p>
					<br />
					<p>
						<b>GC BUSINESS LAWYERS</b> supports its clients through a journey of excellence based on values expressed in:
					</p>
					<br />
					<ul className='ul-gclawyers laptop:px-6'>
						<li>
							Quality personalized services through recognized know-how						
							</li>
						<li>
							Reactive listening and permanent availability for solid legal advice
						</li>
						{/* <li>
							Un domaine professionnel dans la gestion rigoureuse des dossiers et leur traitement
						</li> */}
						<li>
							A professional domain in the rigorous management of files and their processing
						</li>
					</ul>
				</div>
			</AnimationOnScroll>
		</div>
	)
}

export default Valeurs