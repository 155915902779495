import './App.css';
// import AppContainer from './Components';
import {
    Routes,
    BrowserRouter as Router,
    // Navigate,
    useLocation,
    Route
} from "react-router-dom";
import Home from './Components/pages/Home';

import Services from './Components/pages/Services';
import ResponsiveAppBar from './Components/Costums/Appbar';
import Valeurs from './Components/pages/Valeurs';
import React from 'react';

import Footer from './Components/Costums/Footer';
import FooterEN from './Components/Costums/FooterEN';
import FooterES from './Components/Costums/FooterES';

import Alliance from './Components/pages/Alliance';
import Contact from './Components/pages/Contact';
// import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Avis from './Components/pages/legal/Avis';
import { Security } from './Components/pages/legal/Security';
import Privacy from './Components/pages/legal/Privacy';
import useStore from './store/storeGlobal';

// import HomeEN from './Components/pages/EN/HomeEN';
import ServicesEN from './Components/pages/EN/ServicesEN';
import ValeursEN from './Components/pages/EN/ValeursEN';
import AllianceEN from './Components/pages/EN/AllianceEN';
import ContactEN from './Components/pages/EN/ContactEN';

// import HomeES from './Components/pages/ES/HomeES';
import ServicesES from './Components/pages/ES/ServicesES';
import ValeursES from './Components/pages/ES/ValeursES';
import AllianceES from './Components/pages/ES/AllianceES';
import ContactES from './Components/pages/ES/ContactES';

import { SecurityEN } from './Components/pages/legal/EN/Security';
import PrivacyEN from './Components/pages/legal/EN/Privacy';
import AvisEN from './Components/pages/legal/EN/AvisEN';
import AvisES from './Components/pages/legal/ES/Avis';
import PrivacyES from './Components/pages/legal/ES/Privacy';
import { SecurityES } from './Components/pages/legal/ES/Security';
import { useCookies } from 'react-cookie';

function App() {
    const [HeightHeaderImg, setH] = React.useState()
    const [ShowCookies, setShowCookies] = React.useState(true)
    const [currentLocation, setcurrentLocation] = React.useState('/')
    const location = useLocation()
    const [cookies, setCookie] = useCookies(['lng']);
    const globalLng = useStore(state => state.Languge)
    React.useEffect(() => {
        if (cookies && cookies.cookies === 'Yes') {
            setShowCookies(false)
            if (cookies && cookies.langague) {
                useStore.setState({ Languge: cookies.langague })
            }
        }
        if (cookies && cookies.cookies === 'No') {
            setShowCookies(false)
        }
        const hDiv = document.getElementById('BgImage')
        setH(hDiv.clientHeight)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (location.pathname !== currentLocation) {
            setcurrentLocation(location.pathname)
            window.scrollTo({ top: 0, behavior: 'auto' })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])
    if (globalLng === 'FR') {
        return (
            <div className="App relative">
                <ResponsiveAppBar HeightHeaderImg={HeightHeaderImg} />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="Serv" element={<Services />} />
                    <Route path="Vals" element={<Valeurs />} />
                    <Route path="Alliance" element={<Alliance />} />
                    <Route path="Contact" element={<Contact />} />
                    <Route path="Legal" element={<Avis />} />
                    <Route path="Priv" element={<Privacy />} />
                    <Route path="Sec" element={<Security />} />
                    {/* <Route path="*" element={<Navigate to="/" />} /> */}
                </Routes>
                <Footer />
                {ShowCookies && (
                    <div className='fixed bottom-0 right-0 left-0 border bg-white py-4 px-8 flex justify-between items-center laptop:flex-col laptop:px-2'>
                        <p className='text-xs font-semibold w-[70%] laptop:w-[95%] laptop:mb-4 '>
                            Nous utilisons des cookies sur notre site Web pour vous offrir l'expérience la plus pertinente en mémorisant vos préférences et vos visites répétées.
                            En cliquant sur "Accepter tout", vous consentez à l'utilisation de TOUS les cookies.
                        </p>
                        <div className='flex justify-between items-center'>
                            <button
                                onClick={() => {
                                    setCookie('cookies', 'No');
                                    setShowCookies(false)
                                }}
                                className='border border-red-600 py-2 px-3 text-xs font-bold text-white bg-red-600 mx-2'>
                                Refuser
                            </button>
                            <button
                                onClick={() => {
                                    setCookie('cookies', 'Yes');
                                    setShowCookies(false)
                                }}
                                className='border border-green-600 py-2 px-3 text-xs font-bold text-white bg-green-600 mx-2'>
                                Accepter tout                            </button>
                        </div>
                    </div>
                )}
            </div>
        )
    }
    if (globalLng === 'ES') {
        return (
            <div className="App relative">
                <ResponsiveAppBar HeightHeaderImg={HeightHeaderImg} />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="Serv" element={<ServicesES />} />
                    <Route path="Vals" element={<ValeursES />} />
                    <Route path="Alliance" element={<AllianceES />} />
                    <Route path="Contact" element={<ContactES />} />
                    <Route path="Legal" element={<AvisES />} />
                    <Route path="Priv" element={<PrivacyES />} />
                    <Route path="Sec" element={<SecurityES />} />
                </Routes>
                <FooterES />
                {ShowCookies && (
                    <div className='fixed bottom-0 right-0 left-0 border bg-white py-4 px-8 flex justify-between items-center laptop:flex-col laptop:px-2'>
                        <p className='text-xs font-semibold w-[70%] laptop:w-[95%] laptop:mb-4 '>
                        Usamos cookies en nuestro sitio web para brindarle la experiencia más relevante recordando sus preferencias y visitas repetidas.
                         Al hacer clic en "Aceptar todo", acepta el uso de TODAS las cookies.
                        </p>
                        <div className='flex justify-between items-center'>
                            <button
                                onClick={() => {
                                    setCookie('cookies', 'No');
                                    setShowCookies(false)
                                }}
                                className='border border-red-600 py-2 px-3 text-xs font-bold text-white bg-red-600 mx-2'>
                                Rechazar
                            </button>
                            <button
                                onClick={() => {
                                    setCookie('cookies', 'Yes');
                                    setShowCookies(false)
                                }}
                                className='border border-green-600 py-2 px-3 text-xs font-bold text-white bg-green-600 mx-2'>
                                Aceptar todo                           </button>
                        </div>
                    </div>
                )}
            </div>
        )
    }
    if (globalLng === 'EN') {
        return (
            <div className="App relative">
                <ResponsiveAppBar HeightHeaderImg={HeightHeaderImg} />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="Serv" element={<ServicesEN />} />
                    <Route path="Vals" element={<ValeursEN />} />
                    <Route path="Alliance" element={<AllianceEN />} />
                    <Route path="Contact" element={<ContactEN />} />
                    <Route path="Legal" element={<AvisEN />} />
                    <Route path="Priv" element={<PrivacyEN />} />
                    <Route path="Sec" element={<SecurityEN />} />
                </Routes>
                <FooterEN />
                {ShowCookies && (
                    <div className='fixed bottom-0 right-0 left-0 border bg-white py-4 px-8 flex justify-between items-center laptop:flex-col laptop:px-2'>
                        <p className='text-xs font-semibold w-[70%] laptop:w-[95%] laptop:mb-4 '>
                            We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking “Accept All”,
                            you consent to the use of ALL the cookies.
                        </p>
                        <div className='flex justify-between items-center'>
                            <button
                                onClick={() => {
                                    setCookie('cookies', 'No');
                                    setShowCookies(false)
                                }}
                                className='border border-red-600 py-2 px-3 text-xs font-bold text-white bg-red-600 mx-2'>
                                Refuse
                            </button>
                            <button
                                onClick={() => {
                                    setCookie('cookies', 'Yes');
                                    setShowCookies(false)
                                }}
                                className='border border-green-600 py-2 px-3 text-xs font-bold text-white bg-green-600 mx-2'>
                                Accept All
                            </button>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default App;
