import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const PrivacyES = () => {
    return (
        <div className='privacy'>
            <div id='BgImage' className='h-[100vh] bg-black-op-66 bg-top bg-cover pl-5 flex pt-6 justify-center items-center relative' >
                <AnimationOnScroll animateIn="animate__heartBeat" animateOnce>
                    {/* <img src='/img/white-no-background.png' alt='logo' width={'45%'} /> */}
                    <h1 className='text-white text-gr font-bold text-center bg-tr uppercase laptop:text-xl'>
                        {/* SERVICES */}
                        POLÍTICA DE PRIVACIDAD
                    </h1>
                    {/* <h1 className='text-gr  font-bold mb-0 text-center text-red-600 px-3 py-6'>
                    </h1> */}
                </AnimationOnScroll>
                <div id='downdown' className='absolute bottom-8 bg-gray-700 p-2 hover:scale-110 transition-all rounded hover:cursor-pointer' onClick={() => {
                    const hDiv = document.getElementById('downdown')

                    window.scrollTo({ top: (hDiv.clientHeight + 500), behavior: 'smooth' })
                }}>
                    <KeyboardArrowDownIcon fontSize='large' className='text-red-600 hover:text-white transition-all' />
                </div>
            </div>
            <div className='m-10 laptop:mx-3'>
                <p className='text-gray-600  rounded py-4 px-6 mb-4'>
                    GLOBAL CORPORATION BUSINESS LAWYERS S.L.P.  expone en esta sección la Política de Privacidad corporativa sobre la información de carácter personal que el usuario puede facilitar cuando visite nuestro Web site. La Política de Privacidad de GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. está presidida por el cumplimiento de la legislación vigente en materia de protección de datos de carácter personal y la Ley de Servicios de la Sociedad de la Información y Comercio Electrónico.
                </p>
                <p className='font-bold ml-10 text-gray-600 laptop:mr-5' style={{ fontWeight: 'bold' }}>
                    1. Uso y tratamiento de datos de carácter personal
                </p>
                <br />
                <p className='text-gray-600 mx-10'>
                    En cumplimiento de lo establecido en la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal (en adelante, LOPD), GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. le informa de que los datos de carácter personal por Vd. proporcionados, así como aquellos datos a los que se pueda acceder como consecuencia de su navegación, serán incorporados a los ficheros automatizados de GLOBAL CORPORATION BUSINESS LAWYERS S.L.P.

                    <br />
                    GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. puede recabar información de los usuarios a través del presente sitio web por cualquiera de las siguientes vías:
                </p>
                <br />
                <p className='text-gray-600 mx-10'>
                    - Información facilitada por el propio usuario a través de las direcciones de contacto (correo electrónico).
                    <br />
                    -  Procesos de registro para determinados servicios proporcionados en la página web.
                </p>
                <br />

                {/* <br /> */}
                <p className='text-gray-600 px-6'>
                    La información personal de identificación se utilizará exclusivamente para responder a sus solicitudes y para facilitarle información sobre GLOBAL CORPORATION BUSINESS LAWYERS S.L.P.

                    <br />
                    A su vez, se podrá utilizar para otros fines, pero siempre y cuando se le solicite previamente y se obtenga su consentimiento expreso y específico para ello.

                    <br />
                    Los datos que usted facilita se utilizarán exclusivamente para ofrecerle servicios de información dentro del ámbito para el que fueron solicitados, posibilitando la actualización de los datos de registro para el caso no desear recibir más información o modificar el contenido de la misma.

                    <br />
                    GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. trata la información personal con estricto cumplimiento de la legislación en materia de Datos Personales, en particular de la Ley Orgánica 15/1999, de Protección de Datos de Carácter Personal, y del RD 1720/2007.


                    <br />
                    En este sentido todos los datos personales recabados serán incorporados a ficheros dados de alta en el Registro General de Protección de Datos a nombre de GLOBAL CORPORATION BUSINESS LAWYERS S.L.P., cumpliendo con todas las obligaciones establecidas, especialmente las relativas a cumplimiento de los principios de información y consentimiento.

                    <br />
                    Asimismo GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. tiene establecidos conforme al Reglamento de Medidas de Seguridad políticas y procedimientos de seguridad tecnológica y operativa para proteger su información personal contra la pérdida, uso erróneo, modificación o destrucción no intencionada, preservando en todo caso la confidencialidad de la misma.


                    <br />
                    En aquellos casos donde sea necesario cumplimentar un formulario (inscripción a seminarios o eventos), o remitir un correo electrónico (contacta: contact@gcblawyers.com) y hacer click en el botón de enviar, su realización implicará necesariamente que haya sido informado (en virtud del artículo 5 LOPD) y, en su caso, ha otorgado el correspondiente consentimiento (a tenor del artículo 6 LOPD) al contenido de la presente política de privacidad.


                    <br />
                    Así, siempre que GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. le solicite datos de carácter personal, incluirá el correspondiente clausulado legal y un enlace (link) vinculado a la presente Política con el propósito de hacerle partícipe de sus derechos y obligaciones establecidos en la LOPD y LSSICE.

                </p>
                <br />
                <br />
                <p className='font-bold ml-10 text-gray-600 laptop:mr-5' style={{ fontWeight: 'bold' }}>
                    2. Ejercicio de derechos: acceso, rectificación, cancelación y oposición
                </p>
                <br />
                <p className='text-gray-600 mx-10'>
                    Aquellas personas físicas que hayan facilitado sus datos a GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. podrán dirigirse a la misma, en su calidad de Responsable del fichero, con el fin de poder ejercitar gratuitamente sus derechos de acceso, rectificación, cancelación y oposición respecto de los datos incorporados en sus ficheros. Dado el carácter confidencial de la información, Vd. no podrá ejercitar sus derechos telefónicamente, debido a que este medio no permite acreditar su identidad como titular de los datos registrados. El interesado podrá ejercitar sus derechos mediante comunicación por escrito dirigida a GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. en la siguiente dirección: ATT: Responsable LOPD. Paseo de la Castellana nº 81, C.P. 28046 (Madrid), con la siguiente referencia en su carta: “Ejercicio de derechos” (junto a su solicitud escrita y firmada, deberá acreditar su personalidad aportando fotocopia de su D.N.I.).


                </p>
                <br />
                <br />
                <p className='font-bold ml-10 text-gray-600 laptop:mr-5' style={{ fontWeight: 'bold' }}>
                    3. Modificación de la política de privacidad
                </p>
                <br />
                <p className='text-gray-600 mx-10'>
                    GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. se reserva el derecho a modificar su Política de Privacidad, de acuerdo a su propio criterio, o motivado por un cambio doctrinal de la Agencia Española de Protección de Datos, legislativo o jurisprudencial. Cualquier modificación de la Política de Privacidad será publicada al menos diez días antes de su efectiva aplicación. El uso del Web después de dichos cambios implicará la expresa aceptación de estos.
                </p>
            </div>
            <br />
            <br />
        </div>
    )
}

export default PrivacyES