import React from 'react'
// import RecipeReviewCard from '../utilsPages/Card'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { servicesDomainsES } from '../../utilsData/constants';
import { Icon } from '@mui/material';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
const Services = () => {
	const listEl = [
		'Derecho internacional de los negocios ',
		'Derecho Tributario y Fiscalidad Internacional',
		'Derecho comercial y de la competencia',
		'Propiedad intelectual e industrial',
		'Derecho de sociedades ',
		'Derecho penal de los negocios internacionales',
		'Corporate Finance',
		'Transacciones, Fusiones y Adquisiciones',
		'Gobierno corporativo y Corporate Compliance ',
		'Reccbro de deudas internacionales',
	]

	return (
		<div>
			<div id='BgImage' className='h-[100vh] bg-black-33 bg-top bg-cover pl-5 flex pt-6 justify-center items-center relative' >
				<AnimationOnScroll animateIn="animate__heartBeat" animateOnce>
					{/* <h1 className='text-gr  font-bold mb-0 text-center text-red-600 px-3 py-6'>
					</h1> */}
					{/* La forme d'art la plus fascinante <br/> c’est être les meilleurs <br/> grâce à nous en affaires */}
					<h1 className='text-white text-gr font-bold text-center bg-tr uppercase laptop:text-3xl'>
						SERVICIOS
					</h1>
				</AnimationOnScroll>
				<div id='downdown' className='absolute bottom-8 bg-gray-700 p-2 hover:scale-110 transition-all hover:cursor-pointer rounded' onClick={() => {
					const hDiv = document.getElementById('downdown')

					window.scrollTo({ top: (hDiv.clientHeight + 500), behavior: 'smooth' })
				}}>
					<KeyboardArrowDownIcon fontSize='large' className='text-red-600 hover:text-white transition-all' />
				</div>
			</div>
			{/* <h1 className='text-1xl  font-bold mb-0 text-center bg-blue-700 text-white px-3 py-6'>
				SERVICES
			</h1> */}
			<AnimationOnScroll animateIn="animate__backInLeft" animateOnce >
				<div className='py-20 mx-10 laptop:mx-5'>
					<h1 className='text-2xl rounded-r-2xl font-bold mb-0 text-center px-3 py-6'>
						Nuestra experiencia y conocimientos para su despliegue internacional

					</h1>
					<p className='px-8 py-4 border-l-2 border-l-red-700 text-justify'>
						El soporte jurídico de <b>GC BUSINESS LAWYERS</b> permite asistir a las empresas en su implantación internacional tras un exhaustivo análisis del entorno, con el fin de identificar y evaluar adecuadamente las garantías legales y reglamentarias necesarias para la seguridad de cualquier inversión internacional.
						<br />
						<br />
						A través de su asociación con la <b>ALIANZA</b>, el bufete de abogados <b>GC BUSINESS LAWYERS</b> no solo identifica los mercados adecuados para los servicios y productos de las empresas a las que brinda asesoramiento legal para su despliegue internacional, sino que también garantiza que todos los procesos legales y reglamentarios específicos de cada país son respetados, con culturas, idiomas y patrones de trabajo muy diferentes entre sí para hacer de esta diversidad un punto fuerte de desarrollo hacia sus clientes.
					</p>
				</div>
			</AnimationOnScroll>
			<div className=' py-20 px-10 bg-black text-white laptop:px-5'>
				<h1 className='text-2xl rounded-r-2xl font-bold mb-0 text-center px-3 py-6'>
					Áreas de experiencia
				</h1>
				<p className='px-8 py-4 border-l-2 border-l-red-700 text-justify'>
					La experiencia de <b>GC BUSINESS LAWYERS</b> y sus socios internacionales le permite ofrecer servicios especializados en diversos campos.
					<br />
					<br />
				</p>
				<ul className='ul-gclawyers border-l-2 border-l-red-700'>
					{listEl.map((el, ind) => (
						<AnimationOnScroll key={el} delay={ind / 1.5} animateIn="animate__fadeInDown" animateOnce>
							<li>{el}</li>
						</AnimationOnScroll>
					))}
				</ul>
			</div>
			<div>
				<div className=' grid 	grid-cols-2 gap-2 py-14 px-10 laptop:px-4 laptop:grid-cols-1'>
					{servicesDomainsES.map((el, ind) => (
						<AnimationOnScroll key={el.title} delay={ind / 1.5} animateIn="animate__fadeInDown" animateOnce>
							<div className='pt-8 px-4 border rounded m-2 h-[20rem] laptop:h-auto'>
								{/* <Icon baseClassName="fas" className="fa-plus-circle" color="info" /> */}
								<div className='flex justify-center items-center mb-5'>
									<AlignHorizontalLeftIcon />
									<h1 className='font-bold m-0 ml-4 text-xl'>{el.title}</h1>
								</div>
								<div className=''>
									{el.text.map((text, ind) => (
										<p key={ind} className='py-2 pl-4 text-sm'dangerouslySetInnerHTML={{ __html: text }} />
										))}
								</div>
							</div>
						</AnimationOnScroll>
					))}
				</div>
				{/* <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce>
					<div className='flex justify-center items-center py-10 px-10 pt-2'>
						<div className='py-20 px-4 border flex flex-col justify-center items-center rounded m-2'>
							<div className='flex justify-center items-center mb-4'>
								<AlignHorizontalLeftIcon />
								<h1 className='font-bold m-0 ml-4'>Recouvrement de créances internationales</h1>
							</div>
							<div className=''>
								<p className='py-2 pl-4 '>
									GC BUSINESS LAWYERS et son réseau à l’international apportent à leurs sociétés clientes une connaissance de la législation de la plupart des pays internationaux pour récupérer leurs créances auprès de leurs clients internationaux. Elle s’engage à donner dans de brefs délais après étude du dossier une réponse sur la possibilité de recouvrir les créances à court terme.
								</p>
							</div>
						</div>
					</div>
				</AnimationOnScroll> */}
			</div>
		</div>
	)
}

export default Services