import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const SecurityEN = () => {
  return (
    <div className=''>
      <div id='BgImage' className='h-[100vh] bg-black-op-66 bg-top bg-cover pl-5 flex pt-6 justify-center items-center relative' >
        <AnimationOnScroll animateIn="animate__heartBeat" animateOnce>
          {/* <img src='/img/white-no-background.png' alt='logo' width={'45%'} /> */}
          <h1 className='text-white text-gr font-bold text-center bg-tr uppercase laptop:text-xl'>
            Security policy
          </h1>
          {/* <h1 className='text-gr  font-bold mb-0 text-center text-red-600 px-3 py-6'>
                        Politique de privacité
            </h1> */}
        </AnimationOnScroll>
        <div id='downdown' className='absolute bottom-8 bg-gray-700 p-2 hover:scale-110 transition-all rounded hover:cursor-pointer' onClick={() => {
          const hDiv = document.getElementById('downdown')

          window.scrollTo({ top: (hDiv.clientHeight + 500), behavior: 'smooth' })
        }}>
          <KeyboardArrowDownIcon fontSize='large' className='text-red-600 hover:text-white transition-all' />
        </div>
      </div>
      <div className='m-10 py-10 laptop:mx-5'>
        <p className='text-gray-600 border rounded p-4 mb-4'>
        GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. SA’s policy is to have an information security management system adapted to the current technological environment, considering that information is an essential asset for the
          efficient provision of our services and decision making, which is why there is an explicit commitment to protecting information in general as part of a strategy focused on business continuity, risk management
          and the consolidation of a culture of security.
          <br />
          To this end, GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. makes the following commitments:
          <ul className='text-gray-600  p-8 ul-non laptop:px-1'>
            <li>
              Define, implement and maintain an information security management system based on the ISO 27001 standard, the basic elements of which are developed in the application procedures. All this in order to continuously improve the effectiveness and efficiency of the organization, through the
              consideration of stakeholders and the achievement of information security objectives.
            </li>
            <li>Provide the Company with the human and technical resources necessary to provide information.
            </li>
            <li>Create an information security committee, which will be responsible for the maintenance, review and improvement of the company’s information security management system.</li>
            <li>
              Ensure that the information security management system is subject to continuous review, through quality internal audits, which enable critical review and facilitate improvement of the system, and that management conducts a regular review.
            </li>
            <li>Create an emergency plan to ensure the continuity of operations in the event of unforeseen events that could affect information security.

            </li>
            <li>Ensure that appropriate measures are taken to ensure information security.
            </li>
            <li >∙ Inform and train staff on the need:
              <ul className='mx-8 ul-non-inside laptop:mx-2'>
                <li>
                  Obligation of confidentiality and secrecy to be respected with regard to the information to which he has access by virtue of his functions within the organization.
                </li>
                <li>Access to information by confidential and non-transferable password.
                </li>
                <li>Report any incident in the information security system.
                </li>
                <li>Use of resources provided by the company for purposes exclusively related to the activity of the organization.
                </li>
              </ul>
            </li>
            <li>Respect at all times the legislation in force applicable to our activity. By respecting these commitments, GLOBAL CORPORATION BUSINESS LAWYERS S.L.P. guarantees the achievement of the following general objectives:
              <ul className='mx-8 ul-non-inside laptop:px-2'>
                <li>
                  Avoiding, transferring, assuming or reducing risks by setting up and monitoring controls on them, until they reach a level that is bearable by our organization. Thus, if an impact occurs, damage is minimized and business continuity is ensured
                </li>
                <li>
                  Achieve cost savings by rationalizing resources. Elimination of unnecessary and inefficient investments, such as those that overlook or overestimate risks.

                </li>
                <li>Security is seen as a system and becomes a management activity.
                </li>
                <li>
                  Ensure compliance with current legislation and avoid unnecessary risks and costs.
                </li>
                <li> Have control of:
                  <ul className='mx-8 laptop:mx-2'>

                    <li>
                      external access to systems that host applications available to customers (publicly available) to prevent access to unauthorized or crossed data.

                    </li>
                    <li>
                      the possible effects on the system.

                    </li>
                    <li>
                      availability of public services and systems.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>The information security management system will help to improve the competitiveness of the organization by differentiating it from other companies in the sector, improving the image and confidence of our company among potential customers, customers and suppliers, strengthening thus the prestige of the company at national and international levels.
            </li>
          </ul>
        </p>
        <p className='text-gray-600 border rounded p-4 my-4'>
          To achieve these objectives, Management takes the necessary measures to ensure that its information security system:
          <ul className='ml-10'>
            <li className='pt-4'>
              is disseminated in all areas of the Company.
            </li>
            <li>
              is included.
            </li>
            <li>
              is applied.
            </li>
            <li>
              is reviewed and updated.
            </li>
          </ul>
        </p>
        <p className='text-gray-600 border rounded p-4 my-4'>
          The General Management declares that the requirements contained in the documentation constituting the information security management system are met.
        </p>
        {/* <br /> */}
      </div>
    </div>
  )
}
