const servicesDomainsFr = [
    {
        title: 'Droit des affaires internationales',
        text: [
            'La globalisation et l’internationalisation des affaires obligent à une nécessaire connaissance du droit international des affaires, que ce soit à travers la rédaction de contrats internationaux ou la conduite d’opérations transnationales de fusion-acquisition.',
            "<b>GC BUSINESS LAWYERS</b> et son réseau international peut assurer une maîtrise des enjeux internationaux liés aux investissements transnationaux, à la mise en œuvre de contrats faisant intervenir divers pays, langues et cultures ou aux dossiers de contentieux multi-juridictionnels de recouvrement de créances dans lesquels ils doivent intervenir."
        ],
        icon: '__________',
    },
    {
        title: "Droit fiscal et fiscalité internationale",
        text: [
            "<b>GC BUSINESS LAWYERS</b> accompagne et conseille ses clients sur les aspects fiscaux des investissements ou des différentes modalités de déploiement à l’international. La complexité des législations internationales et leur constante évolution exige une veille permanente pour une correcte planification fiscale afin d’anticiper les conséquences et assurer juridiquement les opérations.",
            "Le réseau de partenaires internationaux de <b>GC BUSINESS LAWYERS</b> assure à ses clients une compétence spécifique fiscale et un savoir-faire unique en matière internationale.",

        ],
        icon: "_________",
    },
    {
        title: "Droit commercial et droit de la concurrence",
        text: [
            "<b>GC BUSINESS LAWYERS </b> accompagne ses clients sur le plan juridique dans leurs problématiques liées aux activités de commercialisation de leurs produits et services, aussi bien au niveau national qu’à l’international. Elle peut apporter conseil et contentieux en matière contractuelle et commerciale et une assistance et représentation lors de négociations avec des partenaires internationaux, dans tout le cycle de vie des affaires dans l'exécution de contrats.",

        ],
        icon: "_________",
    },
    {
        title: "Propriété intellectuelle et industrielle",
        text: [
            "La protection et la défense de marques, modèles et brevets auprès des principaux instituts et offices de propriété intellectuelle, tant dans le plan national comme international, est une nécessité aiguë pour les sociétés à vocation internationale, qui sont conscientes de l'importance stratégique et de la spécificité des droits de propriété industrielle. <b>GC BUSINESS LAWYERS</b> accompagne ses clients dans leur obtention, dans leur valorisation et leur exploitation et peut proposer, le cas échéant, la défense active dans le cadre de litiges administratifs et judiciaires.",

        ],
        icon: "_________",
    },
    {
        title: "Droit des sociétés",
        text: [
            "<b>GC BUSINESS LAWYERS </b> accompagne et conseille ses clients, notamment à l'international, dans tous les domaines et actes de la vie des sociétés, depuis leur création jusqu'à leur liquidation et extinction. Elle aide les sociétés en leur définissant et proposant les meilleures règles de fonctionnement dans tout leur cycle de vie.",

        ],
        icon: "_________",
    },
    {
        title: "Droit pénal international des affaires",
        text: [
            "L’exercice d’une activité commerciale dans un monde globalisé peut parfois amener l'entreprise ou leurs dirigeants, parfois sans le savoir, à commettre des infractions pénalement répréhensibles, comme par exemple les atteintes à l’environnement, dans un environnement international des affaires. <b> GC BUSINESS LAWYERS</b> s’efforce pour leurs clients de prévoir en amont les potentiels contentieux en droit pénal des affaires, en encadrant les opérations sachant qu'elles peuvent engager la responsabilité pénale de la société et de ses équipes dirigeantes. Il peut aussi exercer une défense pénale relative à ces possibles infractions.",
        ],
        icon: "_________",
    },
    {
        title: "Corporate Finance",
        text: [
            "<b>GC BUSINESS LAWYERS</b> conseille ses sociétés clientes qui sont à la recherche de solutions en matière de stratégie financière. Elle peut offrir, suivant les besoins, le pilotage de l’exécution de l'opération, en intégrant tous les aspects financiers, juridiques, fiscaux et réglementaires. Son équipe d’experts bilingues et multisectoriels accompagne ses clients dans une approche rigoureuse tout en restant en contact permanent avec les dirigeants de la société pour identifier la solution la plus adaptée.",
        ],
        icon: "_________",
    },
    {
        title: "Transactions, fusions et acquisitions",
        text: [
            "Les opérations de fusions-acquisitions sont des choix stratégiques que font souvent les entreprises dans leur déploiement à l’international, qui doivent être rigoureusement planifiées dans un encadrement juridique. L’expérience des équipes d’experts de <b>GC BUSINESS LAWYERS</b> apporte un conseil juridique de qualité et un accompagnement dans l’ensemble des aspects juridiques et fiscaux de toutes les formes de vente et d’acquisition.",
            // "_________",
            // "_________",

        ],
        icon: "_________",
    },
    {
        title: "Gouvernement d’entreprise et Corporate Compliance",
        text: [
            "<b>GC BUSINESS LAWYERS</b> aide ses sociétés clientes à mettre en place une gouvernance compliance, c’est-à-dire des lignes directrices adaptées à leur propre business pour la prévention, la gestion, le contrôle et la réaction en cas de non-respect éventuel de la réglementation. Elle transforme les contraintes issues des nouvelles exigences, souvent non implantées simultanément dans tous les pays, en opportunités pour réaffirmer leurs procédures internes sans freiner l’activité.",

        ],
        icon: "_________",
    },
    {
        title: "Recouvrement de créances internationales",
        text: [
            "<b>GC BUSINESS LAWYERS</b> et son réseau à l’international apportent à leurs sociétés clientes une connaissance de la législation de la plupart des pays internationaux pour récupérer leurs créances auprès de leurs clients internationaux. Elle s’engage à donner dans de brefs délais après étude du dossier une réponse sur la possibilité de recouvrir les créances à court terme.",
            // "_________",
            // "_________",

        ],
        icon: "_________",
    },
]
export const servicesDomainsEN = [
    {
        title: 'International business law',
        text: [
            'The globalization and internationalization of business requires a necessary knowledge of international commercial law, either through the drafting of international contracts or the performance of transnational merger and acquisition operations.',
            "<b>GC BUSINESS LAWYERS</b> and its international network can ensure mastery of international matters related to transnational investments, the implementation of contracts involving multiple countries, languages and cultures, or multi- jurisdictional debt collection litigation cases in which they are required to intervene.",

        ],
        icon: '__________',
    },
    {
        title: "Tax Law and International Taxation",
        text: [
            "<b>GC BUSINESS LAWYERS</b> supports and advises its clients on the tax aspects of investments or the various modalities of international implementation. The complexity of international laws and their constant evolution requires permanent monitoring for correct tax planning in order to anticipate the consequences and legally secure operations.",
            "<b>GC BUSINESS LAWYERS</b> network of international partners provides its clients with specific tax expertise and unique know-how in international matters.",

        ],
        icon: "_________",
    },
    {
        title: "Commercial and competition law",
        text: [
            "<b>GC BUSINESS LAWYERS</b> supports its clients on the legal level in their issues related to the marketing activities of their products and services, both nationally and internationally. He can provide advice and litigation in contractual and commercial matters and assistance and representation in negotiations with international partners, throughout the business life cycle in the execution of contracts.",
        ],
        icon: "_________",
    },
    {
        title: "Intellectual and industrial property",
        text: [
            "The protection and defense of trademarks, models and patents before the main institutes and intellectual property offices, both nationally and internationally, is an acute necessity for companies with an international vocation, which are aware of the strategic importance and the specificity of industrial property rights. <b>GC BUSINESS LAWYERS</b> supports its clients in obtaining them, in their valuation and their exploitation and can offer, if necessary, active defense in the context of administrative and legal disputes.",

        ],
        icon: "_________",
    },
    {
        title: "Corporate law",
        text: [
            "<b>GC BUSINESS LAWYERS</b> supports and advises its clients, particularly internationally, in all areas and acts of the life of companies, from their creation to their liquidation and extinction. It helps companies by defining and proposing the best operating rules throughout their life cycle. ",

        ],
        icon: "_________",
    },
    {
        title: "International business criminal law",
        text: [
            'The exercise of a commercial activity in a globalized world can sometimes lead the company or their leaders, sometimes without knowing it, to commit criminally reprehensible offenses, such as for example damage to the environment, in an international business environment. <b>GC BUSINESS LAWYERS</b> strives for their clients to anticipate potential disputes in business criminal law upstream, by supervising the operations knowing that they can engage the criminal liability of the company and its management teams. He can also exercise a criminal defense relating to these possible offenses.',
        ],
        icon: "_________",
    },
    {
        title: "Corporate Finance",
        text: [
            '<b>GC BUSINESS LAWYERS</b> advises its corporate clients in the search for financial strategy solutions. It can offer, depending on the needs, the management of the execution of the operation, integrating all the financial, legal, fiscal and regulatory aspects. Its team of bilingual and multisectoral experts accompanies its clients with a rigorous approach while staying in constant contact with company managers to identify the most appropriate solution.',
        ],
        icon: "_________",
    },
    {
        title: "Transactions, Mergers and Acquisitions",
        text: [
            "Mergers and acquisitions are strategic choices that companies often make in their international deployment, which must be rigorously planned within a legal framework. The experience of the expert teams of <b>GC BUSINESS LAWYERS</b> provides quality legal advice and support in all legal and tax aspects of all forms of sale.",
            // "_________",
            // "_________",

        ],
        icon: "_________",
    },
    {
        title: "Governance and Corporate Compliance",
        text: [
            "<b>GC BUSINESS LAWYERS</b> helps its client companies to establish Compliance governance , that is, guidelines adapted to their own business for the prevention, management, control and reaction to possible breaches of the regulations. It transforms the limitations derived from new requirements, often not implemented simultaneously in all countries, into opportunities to reaffirm its internal procedures without slowing down the activity. ",

        ],
        icon: "_________",
    },
    {
        title: "International debt collection",
        text: [
            "<b>GC BUSINESS LAWYERS</b> and its international network provide their business clients with knowledge of the legislation of most international countries to recover their debts from their international clients. It undertakes to give as soon as possible, after studying the file, a response on the possibility of recovering short-term debts. ",
            // "_________",
            // "_________",

        ],
        icon: "_________",
    },
]
export const servicesDomainsES = [




    {
        title: 'Derecho internacional de los negocios',
        text: [
            'La globalización e internacionalización de los negocios requiere un conocimiento necesario del derecho comercial internacional, ya sea a través de la redacción de contratos internacionales o la realización de operaciones transnacionales de fusión y adquisición.',
            "<b>GC BUSINESS LAWYERS</b> y su red internacional pueden asegurar un dominio de los asuntos internacionales relacionados con inversiones transnacionales, la implementación de contratos que involucran varios países, idiomas y culturas o casos de litigios de cobro de deudas multijurisdiccionales en los que deben intervenir.            ",
        ],
        icon: '__________',
    },
    {
        title: "Derecho Tributario y Fiscalidad Internacional",
        text: [
            "<b>GC BUSINESS LAWYERS</b> apoya y asesora a sus clientes en los aspectos fiscales de las inversiones o de las diversas modalidades de implantación internacional. La complejidad de las leyes internacionales y su constante evolución exige un seguimiento permanente para una correcta planificación fiscal con el fin de anticipar las consecuencias y asegurar jurídicamente las operaciones. La red de socios internacionales de <b>GC BUSINESS LAWYERS</b> proporciona a sus clientes una experiencia fiscal específica y un know-how único en asuntos internacionales.",

        ],
        icon: "_________",
    },
    {
        title: "Derecho comercial y de la competencia",
        text: [
            "<b>GC BUSINESS LAWYERS</b> apoya a sus clientes a nivel legal en sus asuntos relacionados con las actividades de comercialización de sus productos y servicios, tanto a nivel nacional como internacional. Puede brindar asesoría y litigio en materia contractual y comercial y asistencia y representación en negociaciones con socios internacionales, a lo largo del ciclo de vida empresarial en la ejecución de contratos.",
        ],
        icon: "_________",
    },
    {
        title: "Propiedad intelectual e industrial",
        text: [
            "La protección y defensa de marcas, modelos y patentes ante los principales institutos y oficinas de propiedad intelectual, tanto a nivel nacional como internacional, es una necesidad acuciante para las empresas con vocación internacional, conscientes de la importancia estratégica y la especificidad de los derechos de propiedad industrial. <b>GC BUSINESS LAWYERS</b> apoya a sus clientes en la obtención de los mismos, en su valoración y en su explotación y puede ofrecer, en caso necesario, una defensa activa en el contexto de litigios administrativos y judiciales.            ",

        ],
        icon: "_________",
    },
    {
        title: "Derecho de sociedades",
        text: [
            "<b>GC BUSINESS LAWYERS</b> apoya y asesora a sus clientes, especialmente a nivel internacional, en todos los ámbitos y actos de la vida de las empresas, desde su creación hasta su liquidación y extinción. Ayuda a las empresas definiendo y proponiendo las mejores reglas de funcionamiento a lo largo de su ciclo de vida. ",

        ],
        icon: "_________",
    },
    {
        title: "Derecho penal de los negocios internacionales",
        text: [
            'El ejercicio de una actividad comercial en un mundo globalizado puede llevar en ocasiones a la empresa o a sus líderes, en ocasiones sin saberlo, a cometer delitos penalmente reprobables, como por ejemplo daños al medio ambiente, en un entorno internacional de negocios. <b>GC BUSINESS LAWYERS</b> se esfuerza para que sus clientes se anticipen a posibles conflictos en el derecho penal empresarial, supervisando las operaciones sabiendo que pueden comprometer la responsabilidad penal de la empresa y sus equipos directivos. También puede ejercer la defensa penal en relación con estos posibles delitos.',
        ],
        icon: "_________",
    },
    {
        title: "Corporate Finance",
        text: [
            '<b>GC BUSINESS LAWYERS</b> asesora a sus clientes corporativos en la búsqueda de soluciones de estrategia financiera. Puede ofrecer, según las necesidades, la gestión de la ejecución de la operación, integrando todos los aspectos financieros, legales, fiscales y regulatorios. Su equipo de expertos bilingües y multisectoriales acompaña a sus clientes con un enfoque riguroso mientras se mantienen en contacto constante con los gerentes de la empresa para identificar la solución más adecuada.',
        ],
        icon: "_________",
    },
    {
        title: "Transacciones, Fusiones y Adquisiciones",
        text: [
            "Las fusiones y adquisiciones son elecciones estratégicas que las empresas suelen hacer en su despliegue internacional, que deben planificarse rigurosamente dentro de un marco legal. La experiencia de los equipos de expertos de <b>GC BUSINESS LAWYERS</b> proporciona asesoramiento y apoyo jurídico de calidad en todos los aspectos legales y fiscales de todas las formas de compraventa.",
            // "_________",
            // "_________",

        ],
        icon: "_________",
    },
    {
        title: "Gobierno corporativo y Corporate Compliance",
        text: [
            "<b>GC BUSINESS LAWYERS</b> ayuda a sus empresas clientes a establecer una gobernanza de Compliance, es decir, directrices adaptadas a su propio negocio para la prevención, gestión, control y reacción ante posibles incumplimientos de la normativa. Transforma las limitaciones derivadas de nuevos requisitos, muchas veces no implementados simultáneamente en todos los países, en oportunidades para reafirmar sus procedimientos internos sin ralentizar la actividad.",

        ],
        icon: "_________",
    },
    {
        title: "Cobro de deudas internacionales",
        text: [
            "<b>GC BUSINESS LAWYERS</b> y su red internacional brindan a sus clientes empresas el conocimiento de la legislación de la mayoría de los países internacionales para recuperar sus deudas de sus clientes internacionales. Se compromete a dar a la mayor brevedad, previo estudio del expediente, una respuesta sobre la posibilidad de recuperación de las deudas a corto plazo. ",
            // "_________",
            // "_________",

        ],
        icon: "_________",
    },
]


export default servicesDomainsFr