import React from 'react'
// import RecipeReviewCard from '../utilsPages/Card'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { servicesDomainsEN } from '../../utilsData/constants';
import { Icon } from '@mui/material';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
const Services = () => {
	const listEl = [
		'International business law',
		'Tax Law and International Taxation',
		'Commercial and competition law',
		'Intellectual and industrial property',
		'Corporate law',
		'International business criminal law',
		'Corporate Finance',
		'Transactions, Mergers and Acquisitions',
		'Government Corporate and Corporate Compliance',
		'International debt collection',
	]
	return (
		<div>
			<div id='BgImage' className='h-[100vh] bg-black-33 bg-top bg-cover pl-5 flex pt-6 justify-center items-center relative' >
				<AnimationOnScroll animateIn="animate__heartBeat" animateOnce>
					{/* <h1 className='text-gr  font-bold mb-0 text-center text-red-600 px-3 py-6'>
					</h1> */}
					{/* La forme d'art la plus fascinante <br/> c’est être les meilleurs <br/> grâce à nous en affaires */}
					<h1 className='text-white text-gr font-bold text-center bg-tr uppercase laptop:text-3xl'>
						SERVICES
					</h1>
				</AnimationOnScroll>
				<div id='downdown' className='absolute bottom-8 bg-gray-700 p-2 hover:scale-110 transition-all hover:cursor-pointer rounded' onClick={() => {
					const hDiv = document.getElementById('downdown')

					window.scrollTo({ top: (hDiv.clientHeight + 500), behavior: 'smooth' })
				}}>
					<KeyboardArrowDownIcon fontSize='large' className='text-red-600 hover:text-white transition-all' />
				</div>
			</div>
			{/* <h1 className='text-1xl  font-bold mb-0 text-center bg-blue-700 text-white px-3 py-6'>
				SERVICES
			</h1> */}
			<AnimationOnScroll animateIn="animate__backInLeft" animateOnce >
				<div className='py-20 mx-10 laptop:mx-5'>
					<h1 className='text-2xl rounded-r-2xl font-bold mb-0 text-center px-3 py-6'>
						Our experience and know-how for your international deployment
					</h1>
					<p className='px-8 py-4 border-l-2 border-l-red-700 text-justify'>
						The legal support of <b>GC BUSINESS LAWYERS</b> makes it possible to assist companies in their international establishment after an exhaustive analysis of the environment, in order to properly identify and assess the legal and regulatory guarantees necessary for the security of any international investment.
						<br />
						<br />
						Through its partnership with the <b>ALLIANCE</b>, the law firm <b>GC BUSINESS LAWYERS</b> not only identifies the markets that are suitable for the services and products of the companies it provides legal advice for their international deployment, but also ensures that all the specific legal and regulatory processes of each country are respected, with very different cultures, languages and work patterns from one another to make this diversity a strong point of development for its clients.
					</p>
				</div>
			</AnimationOnScroll>
			<div className=' py-20 px-10 bg-black text-white laptop:px-5'>
				<h1 className='text-2xl rounded-r-2xl font-bold mb-0 text-center px-3 py-6'>
					Skills domains				</h1>
				<p className='px-8 py-4 border-l-2 border-l-red-700 text-justify'>
					The experience of <b>GC BUSINESS LAWYERS</b> and its international partners enables it to offer specialised services in various fields.
					<br />
					<br />
				</p>
				<ul className='ul-gclawyers border-l-2 border-l-red-700'>
					{listEl.map((el, ind) => (
						<AnimationOnScroll key={el} delay={ind / 1.5} animateIn="animate__fadeInDown" animateOnce>
							<li>{el}</li>
						</AnimationOnScroll>
					))}
				</ul>
			</div>
			<div>
				<div className=' grid 	grid-cols-2 gap-2 py-14 px-10 laptop:px-4 laptop:grid-cols-1'>
					{servicesDomainsEN.map((el, ind) => (
						<AnimationOnScroll key={el.title} delay={ind / 1.5} animateIn="animate__fadeInDown" animateOnce>
							<div className='pt-8 px-4 border rounded m-2 h-[20rem] laptop:h-auto'>
								{/* <Icon baseClassName="fas" className="fa-plus-circle" color="info" /> */}
								<div className='flex justify-center items-center mb-5'>
									<AlignHorizontalLeftIcon />
									<h1 className='font-bold m-0 ml-4 text-xl'>{el.title}</h1>
								</div>
								<div className=''>
									{el.text.map((text, ind) => (
										<p key={ind} className='py-2 pl-4 text-sm'dangerouslySetInnerHTML={{ __html: text }} />
										))}
								</div>
							</div>
						</AnimationOnScroll>
					))}
				</div>
				{/* <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce>
					<div className='flex justify-center items-center py-10 px-10 pt-2'>
						<div className='py-20 px-4 border flex flex-col justify-center items-center rounded m-2'>
							<div className='flex justify-center items-center mb-4'>
								<AlignHorizontalLeftIcon />
								<h1 className='font-bold m-0 ml-4'>Recouvrement de créances internationales</h1>
							</div>
							<div className=''>
								<p className='py-2 pl-4 '>
									GC BUSINESS LAWYERS et son réseau à l’international apportent à leurs sociétés clientes une connaissance de la législation de la plupart des pays internationaux pour récupérer leurs créances auprès de leurs clients internationaux. Elle s’engage à donner dans de brefs délais après étude du dossier une réponse sur la possibilité de recouvrir les créances à court terme.
								</p>
							</div>
						</div>
					</div>
				</AnimationOnScroll> */}
			</div>
		</div>
	)
}

export default Services