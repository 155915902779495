import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
const AvisEN = () => {
    return (
        <div>  <div id='BgImage' className='h-[100vh] bg-black-op-66 bg-top bg-cover pl-5 flex pt-6 justify-center items-center relative' >
            <AnimationOnScroll animateIn="animate__heartBeat" animateOnce>
                {/* <img src='/img/white-no-background.png' alt='logo' width={'45%'} /> */}
                <h1 className='text-white text-gr font-bold text-center bg-tr uppercase laptop:text-3xl'>
                    {/* SERVICES */}
                    LEGAL NOTICE                </h1>
                {/* <h1 className='text-gr  font-bold mb-0 text-center text-red-600 px-3 py-6'>
        </h1> */}
            </AnimationOnScroll>
            <div id='downdown' className='absolute bottom-8 bg-gray-700 p-2 hover:scale-110 transition-all rounded hover:cursor-pointer' onClick={() => {
                const hDiv = document.getElementById('downdown')

                window.scrollTo({ top: (hDiv.clientHeight + 500), behavior: 'smooth' })
            }}>
                <KeyboardArrowDownIcon fontSize='large' className='text-red-600 hover:text-white transition-all' />
            </div>
        </div>
            <div className='m-10 laptop:mx-5'>
                <p className='text-gray-600 border rounded py-4 px-6 mb-4 '>
                    GLOBAL CORPORATION BUSINESS LAWYERS S.L.P Public limited company, the data of which is given below,
                    <br />
                    <br />
                    Company name: GLOBAL CORPORATION BUSINESS LAWYERS S.L.P
                    <br />
                    NIF B-09927203
                    <br />
                    <br />
                    Registered in the Madrid Commercial Register, Volume 38.989 Folio 1 Section 8 Sheet M-692817 Registration

                    <br />
                    Headquarters : Calle Vistalegre 46, 28270 Colmenarejo-Madrid
                    <br />
                    Main offices:  : Calle Vistalegre 46, 28270 Colmenarejo-Madrid
                    <br />
                    To communicate with us, send an e-mail to

                    <a href='mailto:contact@gcblawyers.com' className='ml-2 font-bold'>  contact@gcblawyers.com</a>

                    <br />
                    <br />
                    in accordance with Law 34/2002, of July 11, of Services of the Information Society and Electronic Commerce, informs you that accessing this site implies knowledge and acceptance of the following terms and conditions :

                    <ul className='ul-number text-gray-600  py-4 px-6'>
                        <li>
                            <b>Users : </b>
                            By user, we mean the person who accesses, browses, uses or participates in the services and activities, free or expensive, developed in gcblawyers.com
                        </li>
                        <li>
                            <b>Use of the website, its services and its content : </b>
                            The access or simple use of gcblawyers.com by the user implies that the user accepts the general conditions published by gcblawyers.com each time he accesses gcblawyers.com.
                            <br />
                            Regarding the contents (information, texts, graphics, sound and / or image files, photographs, drawings, etc.) it is prohibited:
                        </li>
                        <li>
                            <b>Unilateral modification : </b>
                            GLOBAL CORPORATION BUSINESS LAWYERS S.L.P reserves the right to make, at any time and without notice, modifications and updates to the information contained in its site or in the configuration and presentation thereof.
                        </li>
                        <li>
                            <b>Hyperlinks : </b>
                            In gcblawyers.com, the User can find links to other web pages using various buttons, links, banners, etc., which are managed by third parties. GLOBAL CORPORATION BUSINESS LAWYERS S.L.P does not have the power or the human or technical means to know, control or approve any information, content, products or services provided by other websites to which links are established from gcblawyers. com. Consequently, GLOBAL CORPORATION BUSINESS LAWYERS S.L.P cannot assume any responsibility for any aspect relating to the website to which a
                            link is established from gcblawyers.com, in particular, for information and not limitation, on its operation, access, data, information, files , quality and reliability of your products and services, your own links and / or any of your content, in general.

                        </li>
                        <li>
                            <b>Exclusion of guarantees and liability
                                : </b>
                            GLOBAL CORPORATION BUSINESS LAWYERS S.L.P does not guarantee the absence of errors in access to the Web, in its content, or that it is updated, even if it will do its best to, if necessary, avoid them, correct or update them.
                            <br />
                            Access to this website and the use that may be made of the information it contains is the sole responsibility of the person making it.
                            <br />
                            In the event that there is a user and a password to access certain content / services, the user is responsible for all actions he performs with his user ID. In particular, he will be responsible for choosing, as a key and as a reminder, strong passwords and phrases, i.e. numbers and letters and even, in systems which allow it, punctuation marks. and special characters, difficult to guess. In particular, the user will avoid choosing words from the dictionary, words which are related to it (last name, address, date of birth, etc.) or simple words to guess (combinations of names with months, prefixes and suffixes, etc.). GLOBAL CORPORATION BUSINESS LAWYERS S.L.P will not be responsible for the consequences, damages or prejudices which could result from this access or use of the information.
                            <br />
                            <br />
                            GLOBAL CORPORATION BUSINESS LAWYERS S.L.P assumes no liability arising from the connection or the content of third-party links referred to on the site.
                            <br />
                            <br />
                            The unauthorized use of the information contained on this site, as well as the infringement of the intellectual or industrial property rights of GLOBAL CORPORATION BUSINESS LAWYERS S.L.P, will result in the legally established responsibilities. GLOBAL CORPORATION BUSINESS LAWYERS S.L.P declines all responsibility for any security errors that may occur and damage that may be caused to the user’s computer system (hardware and software), files or documents stored therein, due to the presence of viruses in the user’s computer used to connect to the services and content of gcblawyers.com, a malfunction of the browser or the use of non-updated versions of the browser.

                            {/* Les cookies sont un outil utilisé par les serveurs Web pour stocker et récupérer des informations sur leurs visiteurs. Ce sont de petits fichiers texte que nous envoyons à votre ordinateur pour garder une trace de vos préférences et les rappeler à votre retour. GLOBAL CORPORATION BUSINESS LAWYERS utilise des cookies pour faciliter la navigation sur son site et pour améliorer l’efficacité et la personnalisation des services offerts aux utilisateurs. Les cookies utilisés par GLOBAL CORPORATION BUSINESS LAWYERS permettent de stocker des informations sur les préférences et la langue de l’utilisateur, afin de pouvoir afficher des contenus et des offres d’intérêt particulier en accord avec votre profil et d’enregistrer le nombre de fois qu’un message particulier est affiché à un utilisateur (généralement des nouveautés sur le portail). */}
                            {/* L’utilisateur peut librement décider de l’implantation ou non sur son disque dur des cookies utilisés par GLOBAL CORPORATION BUSINESS LAWYERS. */}
                            {/* <br /> */}
                            {/* <br /> */}
                            {/* Dans ce sens, l’utilisateur peut configurer son navigateur pour accepter ou refuser par défaut tous les cookies ou pour recevoir un avertissement à l’écran de réception de chaque cookie et décider à ce moment-là de son implantation ou non sur son disque dur. Pour ce faire, nous vous suggérons la section d’aide de votre navigateur pour savoir comment modifier les paramètres que vous utilisez actuellement. */}
                        </li>
                        <li>
                            <b>Duration : </b>
                            The period of validity of these general conditions therefore coincides with the duration of their exposure, until they are modified in whole or in part, the date on which the modified general conditions will take effect
                            <br />

                            Regardless of the provisions of the special conditions, GLOBAL CORPORATION BUSINESS LAWYERS S.L.P may terminate, suspend or interrupt, at any time without notice, access to the content of the site, without the possibility for
                            the User to demand any compensation. After this termination, the prohibitions of use of the contents set out above in these conditions will remain in force.

                        </li>
                        <li>
                            <b>Applicable law and jurisdiction
                                : </b>
                            These General Conditions will be governed by Spanish law. In particular, Law 34/2002, of July 11, on Services of the Information Society and Electronic Commerce and Organic Law 15/1999, on the Protection of Personal Data (LOPD) and its regulation of application will apply. The Spanish jurisdiction is the only jurisdiction to settle disputes. GLOBAL CORPORATION BUSINESS LAWYERS S.L.P and the user, with express waiver of any other jurisdiction that may correspond to them, submit to the Jurisdiction of the Courts and Tribunals of the user’s domicile for all questions that may be raised or exercised arising from the provision of the web service and its services and contents and on the interpretation, implementation, compliance or non-compliance with the provisions of this directive. In the event that the User has his domicile outside Spain, GLOBAL CORPORATION BUSINESS LAWYERS S.L.P and the user, with express waiver of any other forum that may correspond to them, submit to the Jurisdiction of the Courts of Madrid.
                            <br />
                        </li>
                    </ul>
                </p>
            </div></div>
    )
}

export default AvisEN