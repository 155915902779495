import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const Valeurs = () => {
	return (
		<div>
			<div id='BgImage' className='h-[100vh] bg-black-op-22 bg-top bg-cover pl-5 flex pt-6 justify-center items-center relative' >
				<AnimationOnScroll animateIn="animate__heartBeat" animateOnce>
					{/* <img src='/img/white-no-background.png' alt='logo' width={'45%'} /> */}
					{/* <h1 className='text-gr  font-bold mb-0 text-center text-red-600 px-3 py-6'>
					</h1> */}
					<h1 className='text-white text-gr font-bold text-center bg-tr uppercase laptop:text-3xl'>
						VALEURS
					</h1>
				</AnimationOnScroll>
				<div id='downdown' className='absolute bottom-8 bg-gray-700 p-2 hover:scale-110 transition-all rounded hover:cursor-pointer' onClick={() => {
					const hDiv = document.getElementById('downdown')

					window.scrollTo({ top: (hDiv.clientHeight + 500), behavior: 'smooth' })
				}}>
					<KeyboardArrowDownIcon fontSize='large' className='text-red-600 hover:text-white transition-all' />
				</div>
			</div>
			<AnimationOnScroll animateIn="animate__zoomIn" animateOnce >

				<div className='py-20 mx-12 laptop:mx-8'>
					<p>
						<b>GC BUSINESS LAWYERS</b> se concentre en permanence sur des activités et offre des services qui sont orientés à tout moment sur de fortes valeurs qui convergent vers une attention privilégiée pour l'analyse et la compréhension des besoins de ses clients.
					</p>
					<br />
					<p>
						La grande expérience et expertise des équipes de professionnels de <b>GC BUSINESS LAWYERS</b> et de son réseau international de partenaires juridiques est une garantie permanente de la validité des solutions de développement de partenariats internationaux qu’il propose aux clients.
					</p>
					<br />
					<p>
						Dans un souci d’optimisation des ressources pour assurer un développement durable, <b>GC BUSINESS LAWYERS</b> s’appuie grâce à son appartenance à l’ALLIANCE sur un Système d’Information bénéficiant des dernières technologies faisant appel à l’intelligence artificielle pour une amélioration continue et efficiente de l’ensemble de ses activités.
					</p>
					<br />
					<p>
						<b>GC BUSINESS LAWYERS</b> accompagne ses clients à travers un parcours d’excellence qui repose sur des valeurs manifestées dans :
					</p>
					<br />
					<ul className='ul-gclawyers laptop:px-6'>
						<li>
							Des services personnalisés de qualité grâce à un savoir-faire reconnu
						</li>
						<li>
							Une écoute réactive et une disponibilité permanente pour des conseils juridiques solides
						</li>
						{/* <li>
							Un domaine professionnel dans la gestion rigoureuse des dossiers et leur traitement
						</li> */}
						<li>
							Une maîtrise professionnelle dans la gestion rigoureuse des dossiers et leur traitement
						</li>
					</ul>
				</div>
			</AnimationOnScroll>
		</div>
	)
}

export default Valeurs