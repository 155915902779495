import React from 'react'
import useStore from '../../../store/storeGlobal'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const AllianceEN = () => {
  // const state = useStore()

  return (
    <div>
      <div id='BgImage' className='h-[100vh] bg-black-op-55 bg-top bg-cover pl-5 flex pt-6 justify-center items-center relative' >
        <AnimationOnScroll animateIn="animate__heartBeat">
          {/* <h1 className='text-gr  font-bold mb-0 text-center text-red-600 px-3 py-6'>
          </h1>
						SERVICES */}
          <h1 className='text-white text-gr font-bold text-center bg-tr uppercase laptop:text-3xl'>
            ALIANZA
          </h1>
          {/* <img src='/img/white-no-background.png' alt='logo' width={'45%'} /> */}
        </AnimationOnScroll>
        <div id='downdown' className='absolute bottom-8 bg-gray-700 p-2 hover:scale-110 transition-all rounded hover:cursor-pointer' onClick={() => {
          const hDiv = document.getElementById('downdown')

          window.scrollTo({ top: (hDiv.clientHeight + 500), behavior: 'smooth' })
        }}>
          <KeyboardArrowDownIcon fontSize='large' className='text-red-600 hover:text-white transition-all' />
        </div>
      </div>
      {/* <h1 className='text-1xl font-bold mb-0 text-center bg-blue-700 text-white px-3 py-6'>
      </h1> */}
      <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce >
        <div className='py-20 px-10 laptop:px-6'>
          <h1 className='font-bold text-2xl'>Una red internacional post moderna
          </h1>
          <p className='text-[15px]'>
            <br />
            La ALIANZA es una red internacional post moderna formada por socios prescriptores y estratégicos en diversos sectores que proporcionan al ecosistema diversas oportunidades para acceder a la financiación, a modelos de negocio innovadores y a nuevos mercados internacionales.
            <br />
            <br />
            La ALIANZA se articula en torno a una red internacional de empresas altamente especializadas con gran experiencia en el asesoramiento y la ingeniería en diversos ámbitos jurídicos, fiscales, financieros, técnicos y tecnológicos. Es a través de la presencia de las filiales a nivel internacional (Europa, África y Asia) que las empresas de la ALIANZA, los socios estratégicos y las empresas clientes innovadoras y sostenibles pueden acceder a nuevas oportunidades de colaboración para acceder a nuevos mercados.
            <br />
            <br />
            La ALIANZA está dirigida por un comité de coordinación formado por un equipo de profesionales cualificados con BALDER Business Intelligence como coordinador y representante oficial.
            <br />
            <br />
          </p>
        </div>
      </AnimationOnScroll>
      <div className='py-20 px-10 bg-black text-white text-left laptop:px-6 '>
        <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce >
          <h1 className='font-bold text-2xl'>El fortalecimiento del mercado internacional de inversiones </h1>
          <p className='text-white text-[15px]'>
            <br />
            Según el Monitor de Tendencias de las Inversiones de la Conferencia de las Naciones Unidas sobre Comercio y Desarrollo (UNCTAD), en 2021 los flujos mundiales de inversión directa extranjera directa (IDE) experimentaron un fuerte repunte. En comparación con los 929.000 millones de dólares en 2020, estos flujos habían aumentado en un 77%, hasta situarse en unos 1,65 billones de dólares, superando así los niveles anteriores al COVID-19. Aunque la recuperación de los flujos de inversión hacia los países en desarrollo está aumentando, el estancamiento de las nuevas inversiones en los países menos adelantados, especialmente en las industrias esenciales para el desarrollo de la capacidad de producción y los sectores clave de los Objetivos de Desarrollo Sostenible (ODS) - como la electricidad, la alimentación o la salud - sigue siendo motivo de preocupación.
            El informe de la UNCTAD revela una fuerte confianza de los inversores en los sectores de las infraestructuras, apoyada por unas condiciones de financiación favorables a largo plazo, así como por planes de estímulo y programas de inversión en el extranjero. Para 2022, este informe muestra perspectivas positivas para la IDE.
            <br />
            <br />
            En cuanto al capital inversión, en 2021 ha conocido un año excepcional con inversiones récord, con una adquisición de más de 12.300 empresas por un importe superior a los 1,1 billones de dólares, con un stock de capital préstamo para la inversión que alcanzó los 3,4 billones de dólares. El mercado mundial de fusiones y adquisiciones ha experimentado un enorme auge debido a las inyecciones masivas de liquidez de los bancos centrales para mitigar los efectos de la crisis sanitaria que comenzó en 2020 con el  COVID-19. Pero es sobre todo la consideración de los retos ESG que han colocado la responsabilidad social de las empresas en el seno de las decisiones de inversión, como motor de creación de valor.

            <br />
            <br />
          </p>
        </AnimationOnScroll>
      </div>
      <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce >
        <div className='py-20 px-10 laptop:px-6'>
          <h1 className='font-bold text-2xl'>El caso particular del liderazgo africano</h1>
          <p className='text-[15px]'>
            <br />
            Asistimos a un fortalecimiento del liderazgo africano desde el nacimiento de la Unión Africana en 2002 por iniciativa de Estados líderes (Argelia, Nigeria, Sudáfrica, Etiopía), de ahí la decisión de BALDER Business Intelligence de implantar una Dirección Regional en este continente.
            <br />
            <br />
            Por lo tanto, el desarrollo de ecosistemas comerciales regionales tiene sentido si desde este polo se abarcan los mercados africanos para promover la IDE en diversos sectores de inversión.
          </p>
        </div>
      </AnimationOnScroll>
      <div className='py-20 px-10 bg-black text-white text-left laptop:px-6 '>
        <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce >
          <h1 className='font-bold text-2xl'>El despliegue de la ALIANZA </h1>
          <p className='text-white text-[15px]'>
            <br />
            Es en este contexto que una nueva Alianza Internacional de Promoción de la Inversión Directa Internacional por BALDER Business Intelligence a través de la identificación de socios estratégicos clave se ha establecido para crear condiciones favorables para los inversores (bufetes internacionales de abogados de negocios, cámaras de comercio y organizaciones profesionales, entidades financieras y fondos de inversión y red de influencia de empresas de inteligencia económica) y disfrutar de este entorno prometedor.
            <br />
            <br />
            Al disponer de una amplia red de empresas asociadas a nivel internacional con las que BALDER Business Intelligence ha firmado acuerdos de colaboración, ésta tiene la posibilidad de ofrecer a sus clientes conocimientos económicos, comerciales, legales y fiscales con el único fin de garantizar su éxito total. La sociedad hace todo lo posible para que los diferentes procesos jurídicos y reglamentarios propios de cada país, cultura e idioma sean escrupulosamente respetados y honrados, estableciendo esquemas de trabajo adaptados e individuales.
            <br />
          </p>
        </AnimationOnScroll>
      </div>
      {/* <div className='py-20 px-10 bg-black text-white text-left laptop:px-6 '>
        <h1 className='font-bold text-2xl'>Un affermissement sur le marché de l’investissement international</h1>
        <p className='text-white text-[15px]'>
          <br />
          On assiste à un affermissement du leadership africain depuis la naissance de l’Union Africaine en 2002 à l’initiative d’États pivots (Algérie, Nigeria, Afrique du Sud, Éthiopie) d’où la décision de BALDER Business Intelligence d’implanter son HUB régional pour l’Afrique en Algérie, pays pivot avec un énorme potentiel de développement dans les cinq prochaines années avec la création d’une filiale dans la ville d’Oran, ville avec un grand patrimoine historique en relation avec l’Espagne, son HUB régional pour l’Europe.
          <br />
          <br />
          Les relations entre l’Afrique et l’Europe restent centrées sur les thèmes du développement et de la sécurité. Cependant, le maintien d’une plus grande croissance économique en Afrique en raison d’un impact plus limité de la crise sanitaire provoquée par le COVID-19 et les prévisions d’une croissance moins ferme en Europe devraient inciter les entreprises européennes à regarder de plus près les opportunités du sud (Rive-Sud de la Méditerranée et Afrique).
          <br />
          <br />
          Le développement d’écosystèmes commerciaux régionaux a alors tout son sens si depuis ce pôle central sont ciblés les marchés du nord de l’Afrique avec l’Algérie comme centre névralgique pour promouvoir des investissements directs internationaux en même temps dans tout le continent africain dans divers secteurs d’investissements.
          <br />
          <br />
          C’est dans ce cadre qu’une nouvelle Alliance Afrique-Europe de promotion des investissements directs internationaux par BALDER Business Intelligence à travers l’identification de partenaires stratégiques clés a été mise en place pour créer des conditions favorables aux investisseurs (cabinets internationaux d’avocats d’affaires, chambres de commerces et organismes professionnels, entités financières et fonds d’investissement et réseau d’influence de sociétés d’intelligence économique).
          <br />
          <br />
          Disposant d’un large réseau de sociétés partenaires au niveau national et post frontières avec lesquelles BALDER Business Intelligence a signé des accords de partenariat et elle a la possibilité d’offrir à ses clients des connaissances économiques, commerciales, juridiques et fiscales accrues dans l’unique but de leur assurer un total succès. La société met tout en œuvre pour que les différents processus juridiques et réglementaires propres à chaque pays, à chaque culture et à chaque langue soient scrupuleusement respectés et honorés en mettant en place des schémas de travail adaptés et individuels.
        </p>
      </div> */}
      <div className='py-20 px-10 pt-12 laptop:px-6'>
        <h1 className='font-bold text-4xl text-center mb-16 '>Los miembros de la ALIANZA</h1>
        {/* <hr className='my-12' /> */}
        <div className='flex h-[20rem] border rounded-xl mb-6 laptop:flex-col-reverse   laptop:justify-center laptop:items-start laptop:py-10 laptop:h-auto '>
          <div className='w-1/2 flex flex-col justify-center items-center mx-8 laptop:mx-1 laptop:px-4  laptop:w-full'>
            <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce >
              <a href='https://balderbi.com/' target='_blank' rel="noreferrer">
                <h1 className='text-xl font-bold mb-4 laptop:text-base '>BALDER Business Intelligence</h1>
              </a>
              <p>Sociedad de Desarrollo de Alianzas Internacionales de Negocios y Promoción de la Inversión Directa Extranjera IDE</p>
            </AnimationOnScroll>
          </div>
          <div className='w-1/2 mx-8 flex flex-col justify-center items-center laptop:mb-8 laptop:w-full laptop:mx-2'>
            <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce >
              <a href='https://balderbi.com/' target='_blank' rel="noreferrer">
                <img src='/img/Balder_logo_medium_resolution.png' alt='logo' className='w-[300px] laptop:w-[230px] hover:scale-90 transition-all' />
              </a>
            </AnimationOnScroll>
          </div>
        </div>
        {/* <hr className='my-12' /> */}
        <div className='flex h-[20rem] border rounded-xl mb-6 laptop:flex-col  laptop:justify-center laptop:items-start laptop:py-10 laptop:h-auto'>
          {/* <div className='flex h-[20rem] border rounded-xl mb-6'> */}
          <div className='w-1/2 mx-8 flex flex-col justify-center items-center laptop:mb-8 laptop:w-full laptop:mx-2'>
            <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce>
              <a href='http://nablanalytics.com/' target='_blank' rel="noreferrer">
                <img src='/img/nablanalytics.png' alt='logo' className='w-[300px] laptop:w-[230px] hover:scale-90 transition-all' />
              </a>
            </AnimationOnScroll>
          </div>
          <div className='w-1/2 flex flex-col justify-center items-center mx-8 laptop:mx-1 laptop:px-4  laptop:w-full'>
            {/* <div className='w-1/2 flex flex-col justify-center items-center mx-8'> */}
            <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce >
              <a href='http://nablanalytics.com/' target='_blank' rel="noreferrer">
                <h1 className='text-xl font-bold mb-4 laptop:text-lg'>NABLA Analytics</h1>
              </a>
              <p>
                Empresa internacional de inteligencia económica que concentra sus actividades en recomendaciones estratégicas y estudio de los mercados de inversión en diversos sectores utilizando su plataforma de inteligencia artificial TWELVE Economic Engineering              </p>
            </AnimationOnScroll>
          </div>
        </div>
        {/* <hr className='my-12' /> */}
        <div className='flex h-[20rem] border rounded-xl mb-6 laptop:flex-col-reverse   laptop:justify-center laptop:items-start laptop:py-10 laptop:h-auto '>
          {/* <div className='flex h-[20rem] border rounded-xl mb-6'> */}
          <div className='w-1/2 flex flex-col justify-center items-center mx-8 laptop:mx-1 laptop:px-4  laptop:w-full'>
            {/* <div className='w-1/2 flex flex-col justify-center items-center mx-8'> */}
            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce >
              <a href='http://balderbe.com/' target='_blank' rel="noreferrer">
                <h1 className='text-xl font-bold mb-4 laptop:text-lg'>BALDER Business Ecosystem</h1>
              </a>
              <p>
                Empresa internacional de aceleración de proyectos innovadores, que ofrece un ecosistema híbrido de diversos servicios a las empresas en diversos ámbitos que van desde la administración, estudios, asesoría legal y una infraestructura de cloud computing con una interfaz que permite a los usuarios acceder a sus recursos virtualizados a través de su plataforma POLARIS Digital Solutions especializada en la prescripción de varias soluciones digitales innovadoras y sostenibles.
              </p>
            </AnimationOnScroll>
          </div>
          <div className='w-1/2 mx-8 flex flex-col justify-center items-center laptop:mb-8 laptop:w-full laptop:mx-2'>

            {/* <div className='w-1/2 mx-8 flex flex-col justify-center items-center'> */}
            <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce >
              <a href='http://balderbe.com/' target='_blank' rel="noreferrer">
                <img src='/img/bbe.png' alt='logo' className='w-[300px] laptop:w-[230px] hover:scale-90 transition-all' />
              </a>
            </AnimationOnScroll>
          </div>
        </div>
        <div className='flex h-[20rem] border rounded-xl mb-6 laptop:flex-col   laptop:justify-center laptop:items-start laptop:pt-6 py-10 laptop:h-auto'>
          <div className='w-1/2 mx-8 flex flex-col justify-center items-center laptop:mb-8 laptop:w-full laptop:mx-2'>
            <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce >
              <a href='https://gcblawyers.com/' target='_blank' rel="noreferrer">
                <img src='/img/GC_Black.png' alt='logo' className='w-[300px] laptop:w-[230px] hover:scale-90 transition-all' />
              </a>
            </AnimationOnScroll>
          </div>
          <div className='w-1/2 flex flex-col justify-center items-center mx-8 laptop:mx-1 laptop:px-4  laptop:w-full'>
            {/* <div className='w-1/2 flex flex-col justify-center items-center mx-8'> */}
            <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce >
              <a href='https://gcblawyers.com/' target='_blank' rel="noreferrer">
                <h1 className='text-xl font-bold mb-4 laptop:text-lg'>GC Business Lawyers </h1>
              </a>              <p>
                Bufete de abogados de negocios especializado en Europa, África y Oriente Medio a través de una amplia red de bufetes de abogados asociados presentes en diversas regiones del mundo que permiten a las empresas ser acompañadas en su implantación internacional después de un análisis exhaustivo del entorno comercial y de negocios, para identificar las garantías legales necesarias.
              </p>
            </AnimationOnScroll>
          </div>
        </div>
        {/* <hr className='my-12' /> */}
      </div>
    </div >
  )
}

export default AllianceEN