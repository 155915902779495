import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const Valeurs = () => {
	return (
		<div>
			<div id='BgImage' className='h-[100vh] bg-black-op-22 bg-top bg-cover pl-5 flex pt-6 justify-center items-center relative' >
				<AnimationOnScroll animateIn="animate__heartBeat" animateOnce>
					{/* <img src='/img/white-no-background.png' alt='logo' width={'45%'} /> */}
					{/* <h1 className='text-gr  font-bold mb-0 text-center text-red-600 px-3 py-6'>
					</h1> */}
					<h1 className='text-white text-gr font-bold text-center bg-tr uppercase laptop:text-3xl'>
						VALORES
					</h1>
				</AnimationOnScroll>
				<div id='downdown' className='absolute bottom-8 bg-gray-700 p-2 hover:scale-110 transition-all rounded hover:cursor-pointer' onClick={() => {
					const hDiv = document.getElementById('downdown')

					window.scrollTo({ top: (hDiv.clientHeight + 500), behavior: 'smooth' })
				}}>
					<KeyboardArrowDownIcon fontSize='large' className='text-red-600 hover:text-white transition-all' />
				</div>
			</div>
			<AnimationOnScroll animateIn="animate__zoomIn" animateOnce >

				<div className='py-20 mx-12 laptop:mx-8'>
					<p>
						<b>GC BUSINESS LAWYERS</b> se concentra constantemente en actividades y ofrece servicios orientados en todo momento en sólidos valores que convergen hacia una atención privilegiada para el análisis y comprensión de las necesidades de sus clientes.
					</p>
					<br />
					<p>
						La gran experiencia y especialización de los equipos profesionales de <b>GC BUSINESS LAWYERS</b> y su red internacional de socios jurídicos es una garantía permanente de la validez de las soluciones para el desarrollo de alianzas internacionales que ofrece a los clientes.
					</p>
					<br />
					<p>
						Con el fin de optimizar los recursos para asegurar un desarrollo sostenible, <b>GC BUSINESS LAWYERS</b> cuenta, gracias a su pertenencia a la ALIANZA, con un Sistema de Información que se beneficia de las últimas tecnologías utilizando inteligencia artificial para la mejora continua y la eficiencia en todas sus actividades.
					</p>
					<br />
					<p>
						<b>GC BUSINESS LAWYERS</b> acompaña a sus clientes a través de un camino de excelencia basado en valores expresados en:
					</p>
					<br />
					<ul className='ul-gclawyers laptop:px-6'>
						<li>
							Servicios personalizados de calidad a través de un saber hacer reconocido
						</li>
						<li>
							Escucha reactiva y disponibilidad permanente para una sólida asesoría jurídica
						</li>
						{/* <li>
							Un domaine professionnel dans la gestion rigoureuse des dossiers et leur traitement
						</li> */}
						<li>
							Un dominio profesional en la gestión rigurosa de los expedientes y su tramitación
						</li>
					</ul>
				</div>
			</AnimationOnScroll>
		</div>
	)
}

export default Valeurs