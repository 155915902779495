import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useStore from '../../store/storeGlobal';

const Home = () => {
	const globalLng = useStore(state => state.Languge)
	return (
		<>
			<AnimationOnScroll animateIn="animate__fadeInDown" animateOnce duration={1}>
				<div id='BgImage' className='h-[100vh] bg-top bg-cover pl-5 flex pt-6 justify-center items-center bg-black-op-no relative pr-7' >
					{/* <img src='/img/white-no-background.png' alt='logo' width={'50%'} /> */}
					<h1 className='text-white text-2xl font-bold text-center bg-tr uppercase leading-9 laptop:text-xl'>
						{globalLng === 'FR' && (<> La forme d'art la plus fascinante <br /> c’est D'être les meilleurs <br /> grâce à nous en affaires  </>)}
						{globalLng === 'EN' && (<> THE MOST FASCINATING ART FORM <br /> IS TO BE THE BEST IN BUSINESS <br /> THANKS TO US </>)}
						{globalLng === 'ES' && (<> LA FORMA DE ARTE MÁS FASCINANTE <br /> ES SER LOS MEJORES EN LOS NEGOCIOS <br /> GRACIAS A NOSOTROS </>)}
					</h1>
					<div id='downdown' className='absolute bottom-8 bg-gray-700 p-2 hover:scale-110 transition-all hover:cursor-pointer rounded	' onClick={() => {
						const hDiv = document.getElementById('downdown')

						window.scrollTo({ top: (hDiv.clientHeight + 500), behavior: 'smooth' })
					}}>
						<KeyboardArrowDownIcon fontSize='large' className='text-red-600 hover:text-white transition-all' />
					</div>
				</div>
			</AnimationOnScroll>
			{/* // 	<h1 className='text-3xl font-bold mb-4 text-center bg-orange-700 text-white px-3 py-6'>
		// 		Partenariats internationaux des entreprises, voie d’évolution innovante
		// 	</h1> */}
			<div className='py-20 mx-10 laptop:mx-4'>
				<AnimationOnScroll animateIn="animate__fadeInRight" animateOnce >
					<h1 className='text-1xl rounded-r-2xl font-bold mb-0 text-left px-3 py-6'>
						GLOBAL CORPORATION BUSINESS LAWYERS
					</h1>
				</AnimationOnScroll>
				<AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce >
					<p className='px-8 py-4 border-l-2 border-l-red-600 text-justify laptop:mx-2'>
						{globalLng === 'FR' && (<>
							Le cabinet d’avocats international d’affaires <b>GLOBAL CORPORATION BUSINESS LAWYERS</b> accompagne juridiquement ses clients dans leurs projets de croissance et d'expansion, que ce soit pour le financement international des investissements, pour le développement de partenariats internationaux avec transfert technologique ou la relation clients-fournisseurs pour le développement de nouvelles parts de marchés à l’exportation. Son expérience dans les opérations internationales d’investissements, de fusions-acquisitions, d'achats, de restructuration ou de collaboration est une garantie de succès.
							<br />
							<br />
							L’activité prédominante de <b>GC BUSINESS LAWYERS</b> est le capital-investissement "<i>private equity</i>"
							 qui reste central dans l’ensemble des dossiers d'investissement de ses clients, représentant la plus grosse 
							 partie de son chiffre d’affaires. La force de <b>GC BUSINESS LAWYERS</b> est dans sa capacité à attirer les grands noms 
							 du capital investissement partout dans le monde grâce à un réseau d’experts-collaborateurs bien introduit dans le milieu.
						</>)}
						{globalLng === 'EN' && (<>
							The international business law firm GLOBAL <b>CORPORATION BUSINESS LAWYERS</b> provides legal support to its clients in their growth and expansion projects, whether for the international financing of investments, for the development of international alliances with technology transfer or the relationship with clients-suppliers for the development of new export market shares. His experience in investment operations, mergers and acquisitions, purchases, restructuring or collaborations is a guarantee of success.
							<br />
							<br />
							The predominant activity of <b>GC BUSINESS LAWYERS</b> is the "private equity" investment capital that remains central in all the investment records of its clients, representing the majority of its turnover. The strength of <b>GC BUSINESS LAWYERS</b> is in its ability to attract the big names in investment capital around the world through a network of experts-collaborators well introduced in the sector.
						</>)}
						{globalLng === 'ES' && (<>
							El bufete internacional de abogados de negocios <b>GLOBAL CORPORATION BUSINESS LAWYERS</b> brinda apoyo legal a sus clientes en sus proyectos de crecimiento y expansión, ya sea para la financiación internacional de inversiones, para el desarrollo de alianzas internacionales con transferencia de tecnología o la relación con clientes-proveedores para el desarrollo de nuevos cuotas de mercado de exportación. Su experiencia en operaciones de inversión, fusiones y adquisiciones, compras, reestructuraciones o colaboraciones es garantía de éxito.
							<br />
							<br />
							La actividad predominante de <b>GC BUSINESS LAWYERS</b> es el capital inversión "private equity" que sigue siendo central en el conjunto de los expedientes de inversión de sus clientes, representando la mayor parte de su volumen de negocios. La fuerza de <b>GC BUSINESS LAWYERS</b> está en su capacidad para atraer a los grandes nombres del capital de inversión en todo el mundo a través de una red de expertos-colaboradores bien introducida en el medio.
						</>)}
					</p>
				</AnimationOnScroll>
				<br />
				<br />
				<AnimationOnScroll animateIn="animate__fadeInRight" animateOnce >
					<h1 className='text-1xl rounded-r-2xl font-bold mb-0 text-left px-3 py-6'>
						{globalLng === 'FR' && (<>
							Réseau de partenaires internationaux en expansion permanente
						</>)}
						{globalLng === 'EN' && (<>
							Network of international partners in permanent expansion
						</>)}
						{globalLng === 'ES' && (<>
							Red de socios internacionales en permanente expansión
						</>)}
					</h1>
				</AnimationOnScroll>
				<AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce >
					<p className='px-8 py-4 border-l-2 border-l-red-600 text-justify laptop:mx-2'>
						{globalLng === 'FR' && (<>
							<b>GC BUSINESS LAWYERS</b> coordonne un vaste réseau de cabinets d’avocats d’affaires partenaires présents sur l’ensemble des continents qui permettent d'accompagner les clients investisseurs dans leur implantation internationale avec des réglementations hétérogènes propre à chaque pays, pour pouvoir identifier les meilleures garanties juridiques aux projets d'investissements.

						</>)}
						{globalLng === 'EN' && (<>
							<b>GC BUSINESS LAWYERS</b> coordinates a wide network of associated business law firms present in all continents that allow to accompany investment clients in their international implementation with heterogeneous regulations specific to each country, in order to identify the best legal guarantees for investment projects.

						</>)}
						{globalLng === 'ES' && (<>
							<b>GC BUSINESS LAWYERS</b> coordina una amplia red de bufetes de abogados de negocios asociados presentes en todos los continentes que permiten acompañar a los clientes inversores en su implantación internacional con normativas heterogéneas propias de cada país, para poder identificar las mejores garantías jurídicas para los proyectos de inversión					</>)}
						<br />
					</p>
				</AnimationOnScroll>
			</div>
		</>
	)
}

export default Home