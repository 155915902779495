import React from 'react'
import useStore from '../../../store/storeGlobal'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const AllianceEN = () => {
  // const state = useStore()

  return (
    <div>
      <div id='BgImage' className='h-[100vh] bg-black-op-55 bg-top bg-cover pl-5 flex pt-6 justify-center items-center relative' >
        <AnimationOnScroll animateIn="animate__heartBeat">
          {/* <h1 className='text-gr  font-bold mb-0 text-center text-red-600 px-3 py-6'>
          </h1>
						SERVICES */}
          <h1 className='text-white text-gr font-bold text-center bg-tr uppercase laptop:text-3xl'>
            ALLIANCE
          </h1>
          {/* <img src='/img/white-no-background.png' alt='logo' width={'45%'} /> */}
        </AnimationOnScroll>
        <div id='downdown' className='absolute bottom-8 bg-gray-700 p-2 hover:scale-110 transition-all rounded hover:cursor-pointer' onClick={() => {
          const hDiv = document.getElementById('downdown')

          window.scrollTo({ top: (hDiv.clientHeight + 500), behavior: 'smooth' })
        }}>
          <KeyboardArrowDownIcon fontSize='large' className='text-red-600 hover:text-white transition-all' />
        </div>
      </div>
      {/* <h1 className='text-1xl font-bold mb-0 text-center bg-blue-700 text-white px-3 py-6'>
      </h1> */}
      <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce >
        <div className='py-20 px-10 laptop:px-6'>
          <h1 className='font-bold text-2xl'>A post-modern international network</h1>
          <p className='text-[15px]'>
            <br />
            The ALLIANCE is a post-modern international network made up of prescriptive and strategic partners in various sectors providing the ecosystem with various opportunities for access to finance, innovative business models and new international markets.

            <br />
            <br />
            The ALLIANCE is built around an international network of highly specialised companies with extensive consulting and engineering experience in various legal, tax, financial, technical and technological fields. It is through the presence of international subsidiaries (Europe, Africa and Asia) ALLIANCE companies as strategic partners as well as innovative and sustainable client companies can access new collaboration opportunities ensuring access to new markets.
            <br />
            <br />
            The ALLIANCE is governed by a coordinating committee formed by a team of qualified professionals with BALDER Business Intelligence as coordinator and official representative.
          </p>
        </div>
      </AnimationOnScroll>
      <div className='py-20 px-10 bg-black text-white text-left laptop:px-6 '>
        <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce >
          <h1 className='font-bold text-2xl'>Strengthening the international investment market </h1>
          <p className='text-white text-[15px]'>
            <br />
            According to the United Nations Conference on Trade and Development (UNCTAD) Investment Trends Monitor, global foreign direct investment (FDI) flows rebounded strongly in 2021. They rose 77% to some $1.650 billion, up from $929 billion in 2020, surpassing their pre-COVID levels. Although the resumption of investment flows to developing countries is on the rise, the stagnation of new investment in the least developed countries, particularly in industries essential for the development of production capacities and key sectors of the Sustainable Development Goals (SDGs) - such as electricity, food and health - remains a concern. The UNCTAD report shows strong investor confidence in the infrastructure sectors, supported by favourable long-term financing conditions, as well as stimulus plans and foreign investment programmes. For 2022, this report shows a positive outlook for FDI.

            <br />
            <br />
            In terms of private equity, it had an exceptional year in 2021 with record investments, with an acquisition of more than 12,300 companies for an amount exceeding $1.100 billion, with a capital stock ready for investment that has reached 3.400 billion dollars. The global M&A market has been booming due to massive central bank liquidity injections to mitigate the effects of the health crisis that began in 2020 with COVID. But it is above all the consideration of ESG issues that have placed corporate social responsibility within investment decisions, as an engine of value creation.
            <br />
            <br />
          </p>
        </AnimationOnScroll>
      </div>
      <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce >
        <div className='py-20 px-10 laptop:px-6'>
          <h1 className='font-bold text-2xl'>The special case of African Leadership</h1>
          <p className='text-[15px]'>
            <br />
            There has been a strengthening of African leadership since the birth of the African Union in 2002 at the initiative of pivotal States (Algeria, Nigeria, South Africa, Ethiopia) hence the decision of BALDER Business Intelligence to establish a Regional Directorate in this continent.
            <br />
            <br />
            The development of regional commercial ecosystems then makes sense if from this pole are targeted African markets to promote foreign direct investment (FDI) in various investment sectors.
          </p>
        </div>
      </AnimationOnScroll>
      <div className='py-20 px-10 bg-black text-white text-left laptop:px-6 '>
        <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce >
          <h1 className='font-bold text-2xl'>The deployment of the ALLIANCE</h1>
          <p className='text-white text-[15px]'>
            <br />
            It is in this context that a new International Alliance for the Promotion of International Direct Investment by BALDER Business Intelligence through the identification of key strategic partners has been set up to create favourable conditions for investors (international business law firms, chambers of commerce and professional entities, financial entities and investment funds and networks of economic intelligence firms) and take advantage of this promising environment.
            <br />
            <br />
            With a wide network of international partner companies with which BALDER Business Intelligence has signed partnership agreements, it has the opportunity to offer its clients economic knowledge, increased trade, legal and tax measures with the sole aim of ensuring their full success. The company makes every effort to ensure that the various legal and regulatory processes specific to each country, each culture and each language are scrupulously respected and honored by implementing adapted and individual work patterns.
            <br />
          </p>
        </AnimationOnScroll>
      </div>
      {/* <div className='py-20 px-10 bg-black text-white text-left laptop:px-6 '>
        <h1 className='font-bold text-2xl'>Un affermissement sur le marché de l’investissement international</h1>
        <p className='text-white text-[15px]'>
          <br />
          On assiste à un affermissement du leadership africain depuis la naissance de l’Union Africaine en 2002 à l’initiative d’États pivots (Algérie, Nigeria, Afrique du Sud, Éthiopie) d’où la décision de BALDER Business Intelligence d’implanter son HUB régional pour l’Afrique en Algérie, pays pivot avec un énorme potentiel de développement dans les cinq prochaines années avec la création d’une filiale dans la ville d’Oran, ville avec un grand patrimoine historique en relation avec l’Espagne, son HUB régional pour l’Europe.
          <br />
          <br />
          Les relations entre l’Afrique et l’Europe restent centrées sur les thèmes du développement et de la sécurité. Cependant, le maintien d’une plus grande croissance économique en Afrique en raison d’un impact plus limité de la crise sanitaire provoquée par le COVID-19 et les prévisions d’une croissance moins ferme en Europe devraient inciter les entreprises européennes à regarder de plus près les opportunités du sud (Rive-Sud de la Méditerranée et Afrique).
          <br />
          <br />
          Le développement d’écosystèmes commerciaux régionaux a alors tout son sens si depuis ce pôle central sont ciblés les marchés du nord de l’Afrique avec l’Algérie comme centre névralgique pour promouvoir des investissements directs internationaux en même temps dans tout le continent africain dans divers secteurs d’investissements.
          <br />
          <br />
          C’est dans ce cadre qu’une nouvelle Alliance Afrique-Europe de promotion des investissements directs internationaux par BALDER Business Intelligence à travers l’identification de partenaires stratégiques clés a été mise en place pour créer des conditions favorables aux investisseurs (cabinets internationaux d’avocats d’affaires, chambres de commerces et organismes professionnels, entités financières et fonds d’investissement et réseau d’influence de sociétés d’intelligence économique).
          <br />
          <br />
          Disposant d’un large réseau de sociétés partenaires au niveau national et post frontières avec lesquelles BALDER Business Intelligence a signé des accords de partenariat et elle a la possibilité d’offrir à ses clients des connaissances économiques, commerciales, juridiques et fiscales accrues dans l’unique but de leur assurer un total succès. La société met tout en œuvre pour que les différents processus juridiques et réglementaires propres à chaque pays, à chaque culture et à chaque langue soient scrupuleusement respectés et honorés en mettant en place des schémas de travail adaptés et individuels.
        </p>
      </div> */}
      <div className='py-20 px-10 pt-12 laptop:px-6'>
        <h1 className='font-bold text-4xl text-center mb-16 '>The members of the ALLIANCE</h1>
        {/* <hr className='my-12' /> */}
        <div className='flex h-[20rem] border rounded-xl mb-6 laptop:flex-col-reverse   laptop:justify-center laptop:items-start laptop:py-10 laptop:h-auto '>
          <div className='w-1/2 flex flex-col justify-center items-center mx-8 laptop:mx-1 laptop:px-4  laptop:w-full'>
            <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce >
              <a href='https://balderbi.com/' target='_blank' rel="noreferrer">
                <h1 className='text-xl font-bold mb-4 laptop:text-base '>BALDER Business Intelligence</h1>
              </a>
              <p>International Business Partnership Development Corporation and Promotion of Foreign Direct Investment FDI</p>
            </AnimationOnScroll>
          </div>
          <div className='w-1/2 mx-8 flex flex-col justify-center items-center laptop:mb-8 laptop:w-full laptop:mx-2'>
            <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce >
              <a href='https://balderbi.com/' target='_blank' rel="noreferrer">
                <img src='/img/Balder_logo_medium_resolution.png' alt='logo' className='w-[300px] laptop:w-[230px] hover:scale-90 transition-all' />
              </a>
            </AnimationOnScroll>
          </div>
        </div>
        {/* <hr className='my-12' /> */}
        <div className='flex h-[20rem] border rounded-xl mb-6 laptop:flex-col  laptop:justify-center laptop:items-start laptop:py-10 laptop:h-auto'>
          {/* <div className='flex h-[20rem] border rounded-xl mb-6'> */}
          <div className='w-1/2 mx-8 flex flex-col justify-center items-center laptop:mb-8 laptop:w-full laptop:mx-2'>
            <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce>
              <a href='http://nablanalytics.com/' target='_blank' rel="noreferrer">
                <img src='/img/nablanalytics.png' alt='logo' className='w-[300px] laptop:w-[230px] hover:scale-90 transition-all' />
              </a>            </AnimationOnScroll>
          </div>
          <div className='w-1/2 flex flex-col justify-center items-center mx-8 laptop:mx-1 laptop:px-4  laptop:w-full'>
            {/* <div className='w-1/2 flex flex-col justify-center items-center mx-8'> */}
            <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce >
              <a href='http://nablanalytics.com/' target='_blank' rel="noreferrer">
                <h1 className='text-xl font-bold mb-4 laptop:text-lg'>NABLA Analytics</h1>
              </a>              <p>
                An international economic intelligence company that focuses its activities on strategic recommendations and investment market research in various sectors using its artificial intelligence platform TWELVE Economic Engineering
              </p>
            </AnimationOnScroll>
          </div>
        </div>
        {/* <hr className='my-12' /> */}
        <div className='flex h-[20rem] border rounded-xl mb-6 laptop:flex-col-reverse   laptop:justify-center laptop:items-start laptop:py-10 laptop:h-auto '>
          {/* <div className='flex h-[20rem] border rounded-xl mb-6'> */}
          <div className='w-1/2 flex flex-col justify-center items-center mx-8 laptop:mx-1 laptop:px-4  laptop:w-full'>
            {/* <div className='w-1/2 flex flex-col justify-center items-center mx-8'> */}
            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce >
              <a href='http://balderbe.com/' target='_blank' rel="noreferrer">
                <h1 className='text-xl font-bold mb-4 laptop:text-lg'>BALDER Business Ecosystem</h1>
              </a>
              <p>
                International company for the acceleration of innovative projects, offering a hybrid ecosystem of various services to companies in various fields ranging from administration, studies, legal support and a cloud computing infrastructure with an interface allowing users to access their virtualized resources through its platform POLARIS Digital Solutions specialised in the prescription of several solutions innovative and sustainable digital.
              </p>
            </AnimationOnScroll>
          </div>
          <div className='w-1/2 mx-8 flex flex-col justify-center items-center laptop:mb-8 laptop:w-full laptop:mx-2'>

            {/* <div className='w-1/2 mx-8 flex flex-col justify-center items-center'> */}
            <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce >
              <a href='http://balderbe.com/' target='_blank' rel="noreferrer">
                <img src='/img/bbe.png' alt='logo' className='w-[300px] laptop:w-[230px] hover:scale-90 transition-all' />
              </a>
            </AnimationOnScroll>
          </div>
        </div>
        <div className='flex h-[20rem] border rounded-xl mb-6 laptop:flex-col   laptop:justify-center laptop:items-start laptop:pt-6 py-10 laptop:h-auto'>
          <div className='w-1/2 mx-8 flex flex-col justify-center items-center laptop:mb-8 laptop:w-full laptop:mx-2'>
            <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce >
              <a href='https://gcblawyers.com/' target='_blank' rel="noreferrer">
                <img src='/img/GC_Black.png' alt='logo' className='w-[300px] laptop:w-[230px] hover:scale-90 transition-all' />
              </a>            </AnimationOnScroll>
          </div>
          <div className='w-1/2 flex flex-col justify-center items-center mx-8 laptop:mx-1 laptop:px-4  laptop:w-full'>
            {/* <div className='w-1/2 flex flex-col justify-center items-center mx-8'> */}
            <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce >
              <a href='https://gcblawyers.com/' target='_blank' rel="noreferrer">
                <h1 className='text-xl font-bold mb-4 laptop:text-lg'>GC Business Lawyers </h1>
              </a>              <p>
                Specialised law firm, located in Europe, Africa and the Middle East thanks to a vast network of partner law firms present in various regions of the world that allow companies to be accompanied in their international implementation after a comprehensive analysis of the commercial and business environment, in order to identify the necessary legal guarantees.
              </p>
            </AnimationOnScroll>
          </div>
        </div>
        {/* <hr className='my-12' /> */}
      </div>
    </div >
  )
}

export default AllianceEN